


























































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Mutation, State, Action, Getter } from 'vuex-class';
import _groupBy from 'lodash.groupby';
import _kebabCase from 'lodash.kebabcase';
import { IExchangeReward } from '~/store/inventory/types';
import exchangeGyriTokenMutation from '~/mutations/exchangeGyriToken.gql';
import RequirePasscode from '~/components/Wallet/RequirePasscode.vue';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import TwoFactorAuthInputPrompt from '~/components/ModalPrompts/TwoFactorAuthInputPrompt.vue';
import { IGyriUserItem } from '~/types/user-items';
import AuthQuery from '~/mixins/AuthQuery';
import { TwoFaCheckpoint } from '~/types/two-fa-checkpoints';
import bulkHasTransferLockQuery from '~/queries/bulkHasTransferLock.gql';

import CustomExchangeRevealBackgroundVideo from './CustomExchangeRevealBackgroundVideo.vue';
import CustomExchangeRevealItemCard from './CustomExchangeRevealItemCard.vue';
import redeemTokenCode from '~/mutations/redeem/redeemTokenCode.gql';

enum ExchangeState {
  NOT_EXCHANGING = 'NOT_EXCHANGING',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
  EXCHANGE_IN_FLIGHT = 'EXCHANGE_IN_FLIGHT',
  EXCHANGE_COMPLETE = 'EXCHANGE_COMPLETE',
}

interface ICodeExchangeConfig {
  code: string;
  exchangeRevealVideo?: string;
}

export enum CustomExchangeType {
  GYRI_TOKEN = 'gyri token',
  CODE = 'code',
}

export interface ICustomExchangeConfig {
  type: CustomExchangeType;
  autoOpen: boolean;
  data: IGyriUserItem | ICodeExchangeConfig;
}

@Component({
  components: {
    RequirePasscode,
    FullScreenDialog,
    CustomExchangeRevealBackgroundVideo,
    CustomExchangeRevealItemCard,
    TwoFactorAuthInputPrompt,
  },
})
export default class CustomExchangeReveal extends Mixins(AuthQuery) {
  @Prop(Object) readonly config!: ICustomExchangeConfig;

  @State(inventory => inventory.userItems, { namespace: 'inventory' })
  userItems?: IGyriUserItem[];

  @State(customReveal => customReveal, { namespace: 'customReveal' })
  customReveal!: any;

  @Getter('videoReady', { namespace: 'customReveal' }) videoReady!: boolean;

  @Getter('shouldSkipAnimation', { namespace: 'customReveal' })
  shouldSkipAnimation!: boolean;

  @Getter('shouldShowCards', { namespace: 'customReveal' })
  shouldShowCards!: boolean;

  @Action('setVideoReady', { namespace: 'customReveal' })
  private setVideoReady!: (payload: any) => void;

  @Getter('areCardsLoaded', { namespace: 'customReveal' })
  readonly areCardsLoaded!: boolean;
  @Getter('areCardsRevealed', { namespace: 'customReveal' })
  readonly areCardsRevealed!: boolean;

  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  @Action('setVideoState', { namespace: 'customReveal' })
  private setVideoState!: (payload: any) => void;

  @Action('setTotalCards', { namespace: 'customReveal' })
  private setTotalCards!: (payload: any) => void;

  @Action('resetCustomReveal', { namespace: 'customReveal' })
  private resetCustomReveal!: () => void;

  @Action('setShowCards', { namespace: 'customReveal' })
  private setShowCards!: (payload: any) => void;

  @Action('setSkipAnimation', { namespace: 'customReveal' })
  private setSkipAnimation!: (payload: boolean) => void;

  @Action('loadCard', { namespace: 'customReveal' })
  private loadCard!: (payload: string) => void;

  @Action('revealCard', { namespace: 'customReveal' })
  private revealCard!: (payload: string) => void;

  encryptionPasscode = '';
  ExchangeState = ExchangeState;
  exchangeState = ExchangeState.NOT_EXCHANGING;
  selectedItem: IGyriUserItem | null = null;
  similarItems: IGyriUserItem[] = [];
  rewardsEarned: IExchangeReward[] = [];
  swapCurrencies: Array<any> = [];

  get isVideoReady() {
    return this.videoReady;
  }

  get itemStoreCollection() {
    return _kebabCase(this.selectedItem?.collection);
  }

  get itemStoreCategory() {
    return _kebabCase(this.selectedItem?.gyriTokenClassKey.category);
  }

  get tokenId() {
    return this.selectedItem?.fungible
      ? '0'
      : this.selectedItem?.nonFungibleInstanceId;
  }

  get logo() {
    return this.selectedItem?.logo;
  }

  get getRewardsEarned() {
    return this.rewardsEarned
      .filter(x =>
        this.rewardsEarned.length > 3
          ? x.category != 'Unit' && x.category != 'popcorn'
          : true,
      )
      .slice(0, 3)
      .map(r =>
        parseInt(r.quantity) > 1 || r.instance === '0'
          ? { ...r, name: `${r.quantity} ${r.name}` }
          : r,
      );
  }

  get swapUrl() {
    return process.env.galaswapLinkUrl;
  }

  get currencyInRewards() {
    return this.rewardsEarned.filter(
      r => r.category === 'Unit' && r.additionalKey === 'none',
    );
  }

  get rewardCurrencyIsSwappable() {
    return this.checkCurrencyCanSwap();
  }

  get potentialRewardsImageSample() {
    return this.selectedItem?.gyriExchanges[0].odds
      .slice(0, 8)
      .map(item => item.metadata.image);
  }

  get allCardsLoaded() {
    return this.areCardsLoaded && !this.areCardsRevealed;
  }

  get allCardsRevealed() {
    return this.areCardsLoaded && this.areCardsRevealed;
  }

  get openBtnText() {
    return this.exchangeState === ExchangeState.EXCHANGE_IN_FLIGHT ||
      this.exchangeState === ExchangeState.ENTER_PASSWORD
      ? this.$t('common.cta.opening')
      : this.$t('common.cta.open');
  }

  get buyBtnText() {
    return this.$t('common.cta.buyMore');
  }

  get showBuyBtn() {
    return this.config.type === CustomExchangeType.GYRI_TOKEN;
  }

  get showSkipButton() {
    return (
      this.customReveal.currentVideoState === 'opening' &&
      this.customReveal.totalCards > 0 &&
      !this.shouldShowCards
    );
  }

  get showInventory() {
    return (
      this.config.type === CustomExchangeType.GYRI_TOKEN &&
      this.selectedItem !== null
    );
  }

  get showOpenBtn() {
    return (
      this.config.type === CustomExchangeType.CODE ||
      (this.config.type === CustomExchangeType.GYRI_TOKEN &&
        this.selectedItem !== null)
    );
  }

  get showInventoryButton() {
    return this.selectedItem === null;
  }

  get completeActionText() {
    return this.$t('components.customExchange.next');
  }

  moveObjectToFront(
    arr: IGyriUserItem[],
    key: keyof IGyriUserItem,
    value: string,
  ) {
    const index = arr.findIndex(obj => obj[key] === value);

    if (index !== -1) {
      const objectToMove = arr[index];
      arr.splice(index, 1);
      arr.unshift(objectToMove);
    }
    return arr;
  }

  get formattedSimilarItems() {
    const filters = this.$options.filters;
    const firstItem = this.$route.query.selector as string;
    return Object.entries(_groupBy(this.similarItems, 'name')).map(
      ([name, items]) => {
        const quantity = items.length;
        const pluralizedName =
          quantity > 1 && filters ? filters.pluralize(name) : name;
        const groupItems = this.moveObjectToFront(
          items,
          'uniqueInventoryPath',
          firstItem,
        );

        return {
          item: groupItems[0],
          name: name,
          nameWithQuantity: `${quantity} ${pluralizedName}`,
          image: groupItems[0].icon || groupItems[0].image,
        };
      },
    );
  }

  created() {
    if (this.config.type === CustomExchangeType.GYRI_TOKEN) {
      this.selectedItem = this.config.data as IGyriUserItem;
      this.getSimilarItemsToExchange();
      this.getSwapCurrencies();
      this.$watch('selectedItem', (newVal: object, oldVal: object) => {
        this.$nextTick(() => {
          this.setVideoReady(false);
          this.customReveal.videoBoxType = this.selectedItem?.exchangeRevealVideo;
        });
      });
    } else if (this.config.type === CustomExchangeType.CODE) {
      this.setVideoReady(false);
      this.customReveal.videoBoxType = this.config.data.exchangeRevealVideo;
    }
    if (this.config.autoOpen) {
      setTimeout(() => {
        this.onExchangeClick();
      }, 1000);
    }
  }

  async getSwapCurrencies() {
    let swapTokens = await fetch(
      'https://dex-api-platform-dex-prod-gala.gala.com/v1/tokens',
      {
        method: 'GET',
      },
    )
      .then(res => res.json())
      .then(data => {
        return data.tokens || [];
      });

    this.swapCurrencies = swapTokens;
  }

  checkCurrencyCanSwap() {
    if (this.swapCurrencies.length && this.currencyInRewards.length) {
      return this.swapCurrencies.some(curr => {
        return this.currencyInRewards.some(rew => rew.name === curr.name);
      });
    }
    return false;
  }

  goTo(route: string) {
    this.$ua.trackNavigationEvent({
      from: this.$route.fullPath,
      to: route,
    });
    if (this.config.type === CustomExchangeType.GYRI_TOKEN) {
      this.resetBox();
    } else if (this.config.type === CustomExchangeType.CODE) {
      this.$emit('close');
      this.$emit('closeParent');
    }
    this.$router.push(route);
  }

  openBox() {
    this.setVideoState('opening');
  }

  skipBoxAnimation() {
    this.setShowCards(true);
    this.setSkipAnimation(true);
  }

  completeAction() {
    if (this.config.type === CustomExchangeType.GYRI_TOKEN) {
      this.resetBox();
    } else if (this.config.type === CustomExchangeType.CODE) {
      this.$emit('close');
    }
  }

  resetBox() {
    this.resetCustomReveal();
    this.loadNextItem();
  }

  async getSimilarItemsToExchange() {
    const raritySortKey: { [key: string]: number } = {
      Ancient: 1,
      Legendary: 2,
      Epic: 3,
      Rare: 4,
      Uncommon: 5,
      Common: 6,
    };

    if (!this.userItems) return [];

    const similarItems = this.userItems
      .filter(item => {
        if (
          item.gyriTokenClassKey &&
          item.gyriTokenClassKey?.category ===
            this.selectedItem?.gyriTokenClassKey?.category &&
          item.gyriTokenClassKey?.collection ===
            this.selectedItem?.gyriTokenClassKey?.collection &&
          item.gyriExchanges?.length &&
          item.exchangeRevealVideo
        ) {
          return true;
        }
      })
      .sort(
        (a, b) => raritySortKey[a.rarity.label] - raritySortKey[b.rarity.label],
      );

    const tokenLockResponse = await this.getTokenLockResults(
      similarItems.slice(0, 49),
    );
    this.similarItems = similarItems.filter(item => {
      const itemInstanceId = `${item.sendId}|${item.nonFungibleInstanceId}`;
      const lockMatch = tokenLockResponse.find((lock: any) => {
        return lock.tokenInstanceId === itemInstanceId;
      });
      const canExchangeIfLocked = lockMatch?.allowedActions?.includes(
        'EXCHANGE',
      );
      return !lockMatch || canExchangeIfLocked;
    });
  }

  async getTokenLockResults(items: IGyriUserItem[]) {
    const tokenInstances = items.map(instance => {
      const {
        collection,
        category,
        type,
        additionalKey,
      } = instance.gyriTokenClassKey;
      return {
        instance: instance.fungible ? '0' : instance.nonFungibleInstanceId,
        collection,
        category,
        type,
        additionalKey,
      };
    });

    const tokenLockResponse = await this.$apollo.query({
      query: bulkHasTransferLockQuery,
      variables: { tokenInstances },
      fetchPolicy: 'no-cache',
    });
    return tokenLockResponse?.data?.bulkHasTransferLock ?? [];
  }

  async exchangeToken({
    exchangeId,
    walletPassword,
    tokens,
    totpToken,
  }: {
    exchangeId: number;
    walletPassword: string;
    totpToken: string;
    tokens: Array<{
      collection: string;
      category: string;
      type: string;
      additionalKey: string;
      instance: string;
    }>;
  }) {
    const res = await this.$apollo.mutate({
      mutation: exchangeGyriTokenMutation,
      variables: {
        exchangeId,
        exchangeTokens: tokens.map(
          ({ collection, category, type, additionalKey, instance }) => ({
            tokenInstanceKey: {
              collection,
              category,
              type,
              additionalKey,
              instance,
            },
            quantity: '1',
          }),
        ),
        walletPassword,
        totpToken,
      },
    });

    if (res?.data?.exchangeGyriToken) {
      return {
        exchangeNetwork: 'GYRI',
        response: res?.data?.exchangeGyriToken,
      };
    }

    throw new Error('Something went wrong');
  }

  async redeemCode(code: string) {
    try {
      this.exchangeState = this.ExchangeState.EXCHANGE_IN_FLIGHT;
      const apolloClient = this.$apolloProvider.clients.gateway;
      const { data } = await apolloClient.mutate({
        mutation: redeemTokenCode,
        variables: {
          code,
        },
      });

      if (data?.redeemTokenCode?.success) {
        // UA tracking
        this.$ua.trackRedeemTokenCodeCompleteEvent({
          tokenRedeemedType: 'exchange',
          tokenRedeemedSuccessfully: true,
        });

        return {
          exchangeNetwork: 'GYRI',
          response: {
            rewards: data?.redeemTokenCode.data,
          },
        };
      } else {
        // UA tracking
        this.$ua.trackRedeemTokenCodeErrorEvent({
          message: data?.redeemTokenCode.message,
          redeemTokenCode: data?.redeemTokenCode,
        });

        throw new Error(data?.redeemTokenCode?.message);
      }
    } catch (error) {
      // UA tracking
      this.$ua.trackRedeemTokenCodeErrorEvent(error);
      this.$sentry.captureException(error);
      throw new Error(error.message ?? 'Something went wrong');
    }
  }

  async onExchangeClick() {
    if (this.config.type === CustomExchangeType.CODE) {
      try {
        const data = this.config.data as ICodeExchangeConfig;
        const res = await this.redeemCode(data.code);
        if (res) {
          this.onExchangeComplete(res);
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        console.warn(error);
        this.onExchangeError({
          message: (error as any).message,
        });
      }
    } else if (!this.encryptionPasscode) {
      this.exchangeState = this.ExchangeState.ENTER_PASSWORD;
      return;
    } else {
      try {
        const res = await this.exchangeItems(this.encryptionPasscode);
        if (res) {
          this.onExchangeComplete(res);
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        console.warn(error);
        this.onExchangeError({
          message: (error as any).message,
        });
      }
    }
  }

  exchangeItems(walletPassword?: string) {
    const item = this.selectedItem;

    if (!item || !this.tokenId || item.network !== 'GYRI' || !walletPassword) {
      return;
    }
    this.encryptionPasscode = walletPassword;
    this.exchangeState = this.ExchangeState.EXCHANGE_IN_FLIGHT;
    return this.doAuthQuery(
      async totpToken => {
        const data = await this.exchangeToken({
          exchangeId: item.gyriExchanges[0].id,
          walletPassword,
          totpToken,
          tokens: [
            {
              collection: item.gyriTokenClassKey.collection,
              category: item.gyriTokenClassKey.category,
              type: item.gyriTokenClassKey.type,
              additionalKey: item.gyriTokenClassKey.additionalKey,
              instance: this.tokenId as string,
            },
          ],
        });

        return data;
      },
      TwoFaCheckpoint.transactions,
      { hideDialogDuringQuery: true },
    );
  }

  onExchangeComplete(res: { exchangeNetwork: string; response: any }) {
    this.rewardsEarned = res.response.rewards;
    this.exchangeState = this.ExchangeState.EXCHANGE_COMPLETE;
    this.setVideoState('opening');
    this.setTotalCards(Math.min(res.response.rewards.length, 3));
  }

  onExchangeError(res: { message: string }) {
    this.encryptionPasscode = '';
    this.exchangeState = this.ExchangeState.NOT_EXCHANGING;
    this.updateSnackbarErrorText(res.message);
    this.toggleErrorSnackbar();
    this.setVideoState('loading');
  }

  loadNextItem() {
    if (this.similarItems.length > 1 && this.selectedItem) {
      const indexToRemove = this.similarItems.findIndex(
        item =>
          item.name === this.selectedItem?.name &&
          item.nonFungibleInstanceId ===
            this.selectedItem?.nonFungibleInstanceId,
      );

      this.similarItems.splice(indexToRemove, 1);

      this.selectedItem = this.similarItems[0];
    } else {
      this.selectedItem = null;
      this.similarItems = [];
    }
    this.exchangeState = this.ExchangeState.NOT_EXCHANGING;
  }
}
