


















import { Component, Vue, Watch } from 'vue-property-decorator';
import { Mutation, State, Action, Getter } from 'vuex-class';

@Component({})
export default class CustomExchangeRevealBackgroundVideo extends Vue {
  @State(customReveal => customReveal, { namespace: 'customReveal' })
  customReveal!: any;

  @Getter('videoBoxType', { namespace: 'customReveal' }) videoBoxType!: string;

  @Action('setShowCards', { namespace: 'customReveal' })
  private setShowCards!: (payload: any) => void;

  @Getter('shouldSkipAnimation', { namespace: 'customReveal' })
  shouldSkipAnimation!: boolean;

  @Action('setVideoReady', { namespace: 'customReveal' })
  private setVideoReady!: (payload: any) => void;

  @Action('setVideoState', { namespace: 'customReveal' })
  private setVideoState!: (payload: any) => void;

  shouldLoop: boolean = false;
  vidDrop = 0;
  vidIdle = 4;
  vidOpen = 8;
  cardIn = 10.5;
  vidRevealed = 14;
  vidEnd = 16;

  checkPlayState(e: any) {
    const vid = e.target;
    if (this.shouldSkipAnimation && vid.currentTime < this.vidRevealed) {
      vid.currentTime = this.vidRevealed;
    }
    switch (this.customReveal.currentVideoState) {
      case 'loading':
        vid.removeEventListener('ended', this.loopBack);
        if (
          vid.currentTime >= this.vidRevealed &&
          vid.currentTime <= this.vidEnd
        ) {
          vid.currentTime = this.vidDrop;
        }
        if (vid.currentTime >= this.vidOpen - 0.3) {
          vid.currentTime = this.vidIdle;
        }
        break;
      case 'opening':
        if (vid.currentTime < this.vidOpen) {
          vid.currentTime = this.vidOpen;
        }
        if (vid.currentTime >= this.cardIn) {
          this.setShowCards(true);
        }
        if (vid.currentTime >= this.vidRevealed) {
          this.setVideoState('revealed');
        }
        break;
      case 'revealed':
        vid.addEventListener('ended', this.loopBack);
        break;
    }
  }

  get videoSrc() {
    return this.customReveal.videoBoxType;
  }

  loopBack(e: any) {
    e.target.currentTime = this.vidRevealed;
    e.target.play();
  }

  handleCanPlay(e: any) {
    this.setVideoReady(true);
    e.target.play();
  }

  created() {
    this.$watch('videoBoxType', (newVal: string, oldVal: string) => {
      const videoRef = this.$refs.videoRef as HTMLVideoElement;
      this.$nextTick(() => {
        if (!videoRef.paused) {
          videoRef.pause();
        }
        this.setVideoReady(false);
        videoRef.load();
      });
    });
  }
}
