







































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ItemCard extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly fungible!: boolean;

  @Prop({ type: Number, required: true })
  readonly quantity!: number;

  @Prop({ type: Number, default: 0 })
  readonly ccLockedCount!: number;

  @Prop({ type: String, required: true })
  readonly itemImage!: string;

  @Prop({ type: String, required: true })
  readonly itemName!: string;

  @Prop({ type: String })
  readonly itemNameTextColor?: string;

  @Prop({ type: String })
  readonly animationUrl?: string;

  @Prop({ type: Boolean, default: false })
  readonly currentPlaying!: boolean;

  @Prop({ type: String })
  readonly rarityIcon?: string;

  @Prop({ type: String })
  readonly rarityLabel?: string;

  @Prop({ type: String, required: true })
  readonly chainIcon!: string;

  @Prop({ type: Boolean, default: false })
  readonly bumpUpChainIcon!: boolean;

  @Prop({ type: String, required: true })
  readonly chainIconAltText!: string;

  @Prop({ type: String, required: true })
  readonly chainDisplayName!: string;

  @Prop({ type: Number })
  readonly nameTextWeight?: number;

  @Emit()
  audioAction() {}

  @Emit('click')
  emitClick() {}
}
