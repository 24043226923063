



























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import sendVerifyEmailMutation from '~/mutations/sendVerifyEmail.gql';
import {
  getFromBrowserStorage,
  setInBrowserStorage,
  LocalStorageKeys,
} from '~/utils/browserStorage';

@Component({
  components: { FullScreenDialog },
})
export default class VerifyEmailAddress extends Vue {
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State(profile => profile.user.emailVerified, { namespace: 'profile' })
  emailVerified!: boolean;
  @State(profile => profile.user.email, { namespace: 'profile' })
  email!: boolean;

  @Mutation('updateShowSettingsPage')
  private updateShowSettingsPage!: (args: any) => void;

  modalControl = true;
  sending = false;
  sent = false;
  sendError = '';
  isUserSettingUp2FA = true;
  delayShow = false;

  get showModal() {
    return (
      this.modalControl &&
      this.loggedIn &&
      this.email &&
      !this.emailVerified &&
      this.emailVerified !== undefined &&
      this.$route.name !== 'login' &&
      this.$route.name !== 'register' &&
      !this.isUserSettingUp2FA &&
      !this.delayShow
    );
  }

  created() {
    if (this.$route.query.component !== 'TwoFa') {
      this.isUserSettingUp2FA = false;
    }

    const delay = getFromBrowserStorage<number>(
      LocalStorageKeys.hideEmailVerification,
    );
    if (delay && delay > Date.now()) {
      this.delayShow = true;
    }
  }

  async handleSendVerificationEmail() {
    try {
      this.sending = true;
      const { data } = await this.$apollo.mutate({
        mutation: sendVerifyEmailMutation,
      });

      this.sending = false;

      if (data.sendVerifyEmail && data.sendVerifyEmail.success) {
        // UA tracking
        this.$ua.trackSendEmailVerificationEmailEvent();

        this.sent = true;
      } else {
        throw new Error('failed to save acceptance');
      }
    } catch (error) {
      this.$sentry.captureException(error);
      this.sending = false;
      this.sendError = this.$t(
        'components.emailVerification.verifyEmailAddress.sendError',
      ) as string;
    }
  }

  handleUpdateEmail() {
    this.modalControl = false;
    this.updateShowSettingsPage('email');
    this.$router.push('/account');
  }

  @Watch('modalControl')
  resetState(val: boolean, oldVal: boolean) {
    if (oldVal === true && val === false) {
      this.sending = false;
      this.sent = false;
      this.sendError = '';
    }
  }

  @Watch('showModal')
  setModalDelay(value: boolean) {
    if (value === false) {
      setInBrowserStorage(
        LocalStorageKeys.hideEmailVerification,
        (Date.now() + 1000 * 60 * 60 * 24).toString(), // Hide for 1 day
      );
    }
  }
}
