























































































































































































import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import BottomDrawerDialog from '~/components/UIComponents/BottomDrawerDialog.vue';
import TextOutput from '~/mixins/TextOutput';
import { IGameItemProductPrice } from '~/store/games/types';
import { IWallet } from '~/store/inventory/types';
import { FiatIcons, FiatProvider } from '../../types/fiat-provider';

@Component({
  components: {
    BottomDrawerDialog,
  },
})
export default class PaymentPicker extends Mixins(TextOutput) {
  @State(profile => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State(profile => profile.user.walletConnected, { namespace: 'profile' })
  walletConnected!: boolean;
  @State(inventory => inventory.wallets, { namespace: 'inventory' })
  wallets!: IWallet[];
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State(profile => profile.user.hasGyriPassphrase, { namespace: 'profile' })
  hasGyriPassphrase!: boolean;

  @Prop(Array) readonly prices!: IGameItemProductPrice[];
  @Prop(Object) readonly selectedPrice!: IGameItemProductPrice;
  @Prop(String) readonly productId!: string;
  @Prop(Number) readonly quantity!: number;

  @Getter('w3wConnectionActive', { namespace: 'web3Wallet' })
  readonly w3wConnectionActive!: boolean;

  @Mutation('toggleCreateWalletPrompt')
  private toggleCreateWalletPrompt!: (payload: {
    show?: boolean;
    walletType?: string;
  }) => void;

  selectedCoin: IWallet | null = null;
  selectedItem!: Partial<IWallet>;
  optionsDrawer = false;
  creditCardsAllowed = false;
  fiatWallets: Partial<IWallet>[] = [
    /* Disable MoonPay
    {
      name: this.$t('components.wallet.paymentPicker.moonpay').toString(),
      icon: FiatIcons.moonpay,
      symbol: 'CC',
      fiatProvider: FiatProvider.MOONPAY,
    },
    */
    {
      name: this.$t('components.wallet.paymentPicker.cc-coinflow').toString(),
      icon: FiatIcons.coinflow,
      symbol: 'CC-COINFLOW',
      fiatProvider: FiatProvider.COINFLOW,
    },
    {
      name: this.$t('components.wallet.paymentPicker.cc-stripe').toString(),
      icon: FiatIcons.stripe,
      symbol: 'CC-STRIPE',
      fiatProvider: FiatProvider.STRIPE,
    },
  ];

  private coinSymbols = [
    'GALA',
    'TOWN',
    'MTRM',
    'SILK',
    '$GMUSIC,',
    'ETIME',
    'STAMINA',
    'LEFC',
    'LECC',
    'LEMIN',
    'LECHAR',
    'TOLK',
    'GalaChips',
  ];

  get fiatOptionWallets() {
    const priceSymbols = this.prices.map(p => p.symbol);
    const wallets: Partial<IWallet>[] = this.fiatWallets.filter(
      wallet => priceSymbols.indexOf(wallet.symbol || '') !== -1,
    );

    return wallets;
  }

  get paymentOptionWallets() {
    const priceSymbols = this.prices.map(price =>
      this.coinSymbols.includes(price.symbol)
        ? `${price.symbol}[ETH]`
        : price.symbol,
    );
    const wallets: Partial<IWallet>[] = this.wallets.filter(wallet => {
      return priceSymbols.includes(wallet.symbol);
    });
    return wallets;
  }

  rebateTokenType(wallet: any) {
    const price = this.prices.find(p => {
      return p.symbol === wallet.symbol;
    });

    if (price?.rebate) {
      return price.rebate.tokenClassKey?.collection;
    }

    return '';
  }

  rebateAmountInUsd(wallet: any) {
    const price = this.prices.find(p => {
      return p.symbol === wallet.symbol;
    });

    if (price?.usdPriceInCents) {
      const rebatePercentage = price?.rebate?.percentageOfUsdPurchasePrice;

      if (rebatePercentage) {
        const discountPriceInCents =
          (price.usdPriceInCents * rebatePercentage * this.quantity) / 100;
        return discountPriceInCents / 100;
      }
    }

    return 0;
  }

  handleOpen() {
    if (this.wallets && this.wallets.length) {
      this.optionsDrawer = !this.optionsDrawer;

      if (this.optionsDrawer) {
        this.$ua.trackCurrencySelectorEnteredEvent({
          selectedCurrency: this.selectedCoin?.symbol ?? 'none',
          currencyOptions: this.paymentOptionWallets.map(
            o => o.symbol ?? 'unknown',
          ),
          productId: this.productId ?? 'unknown',
        });
      }
    } else if (!this.walletExists && !this.w3wConnectionActive) {
      this.toggleCreateWalletPrompt({ show: true });
    }
  }

  coinBalance(wallet: IWallet) {
    if (!wallet?.balance?.confirmed || !wallet?.balance?.unconfirmed) return 0;
    return Math.min(+wallet.balance.confirmed, +wallet.balance.unconfirmed);
  }

  handleCoinSelect(wallet: IWallet) {
    const price = this.prices.find(price => {
      const symbol = this.coinSymbols.includes(price.symbol)
        ? `${price.symbol}[ETH]`
        : price.symbol;

      return symbol === wallet.symbol;
    });

    this.selectedCoin = wallet;
    this.selectedItem = this.selectedCoin;
    this.optionsDrawer = false;

    return price;
  }

  handleCreditSelect(wallet: Partial<IWallet>) {
    this.selectedCoin = null;
    this.optionsDrawer = false;
    this.selectedItem = wallet;

    const price =
      this.prices.find(p => p.symbol === wallet.symbol) ?? this.prices[0];

    const priceValue = price.usdPriceInCents
      ? price.usdPriceInCents
      : price.usdBasePriceInCents;
    const priceString = (Math.round(priceValue * 100) / 100 / 100)
      .toFixed(2)
      .toString();
    const basePriceValue = price.usdBasePriceInCents;
    const basePriceString = (Math.round(basePriceValue * 100) / 100 / 100)
      .toFixed(2)
      .toString();

    return {
      price: priceString,
      basePrice: basePriceString,
      usdPriceInCents: priceValue,
      usdBasePriceInCents: basePriceValue,
      rebate: price.rebate,
      symbol: wallet.symbol,
      imageURL: wallet.icon,
      creditCard: true,
      selectedCredit: wallet.fiatProvider,
    };
  }

  checkForMissingGyriPassphrase(coinSymbol: string) {
    if (coinSymbol.toLowerCase().includes('gyri') && !this.hasGyriPassphrase) {
      this.toggleCreateWalletPrompt({ show: true, walletType: 'gyri' });
    }
  }

  @Emit('coin-selected')
  handleOptionSelect(data: IWallet) {
    if (data.symbol.startsWith('CC')) {
      return this.handleCreditSelect(data);
    } else {
      setTimeout(() => this.checkForMissingGyriPassphrase(data.symbol), 10);

      const previousSelection = this.selectedCoin?.symbol ?? 'unknown';
      this.$ua.trackCurrencyChangedEvent({
        newSelection: data.symbol,
        previousSelection,
        productId: this.productId ?? 'unknown',
      });

      return this.handleCoinSelect(data);
    }
  }

  @Watch('selectedPrice', { immediate: true })
  onPricesAvailable(price: IGameItemProductPrice) {
    if (price?.symbol?.startsWith('CC')) {
      // Eventually we will have more providers here
      this.selectedItem =
        this.fiatWallets.find(w => w.symbol === price.symbol) ??
        this.fiatWallets[0];
    } else if (price && this.wallets.length) {
      this.selectedCoin = this.wallets.find(wallet => {
        const symbol = this.coinSymbols.includes(price.symbol)
          ? `${price.symbol}[ETH]`
          : price.symbol;

        return wallet.symbol === symbol;
      }) as IWallet;
      this.selectedItem = this.selectedCoin;
    }
  }

  @Watch('wallets')
  onWalletsFetched(wallets: IWallet[]) {
    if (
      wallets.length &&
      !this.selectedCoin &&
      this.prices.length &&
      this.selectedPrice
    ) {
      this.selectedCoin = wallets.find(
        wallet => wallet.symbol === this.selectedPrice.symbol,
      ) as IWallet;
    }
  }

  @Watch('prices', { immediate: true })
  onPricesUpdated(newPrices: IGameItemProductPrice[]) {
    const priceSymbols = newPrices.map(price => price.symbol);
    this.creditCardsAllowed =
      !!process.env.creditCardIsEnabled &&
      priceSymbols.some(value => value.startsWith('CC'));
    // Here we're updating the selected payment method, which also contains price info, so that if a discount code is applied
    // after the payment method has already been selected, the selected price info gets updated correctly
    if (this.selectedItem?.symbol?.startsWith('CC')) {
      const selectedPaymentInfo = this.handleCreditSelect(this.selectedItem);
      this.$emit('coin-selected', selectedPaymentInfo);
    } else if (this.selectedCoin) {
      const selectedPaymentInfo = this.handleCoinSelect(this.selectedCoin);
      this.$emit('coin-selected', selectedPaymentInfo);
    }
  }
}
