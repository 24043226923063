import { Context } from '@nuxt/types';

export default async function(context: Context) {
  const { redirect, store, route } = context;
  const { state } = store;

  const isInventoryRoute = route.fullPath?.includes('/balances');
  const tokenBracketsContent = /\[\w*\]/.exec(route.fullPath);
  const tokenBracketsContentLowercase =
    tokenBracketsContent &&
    tokenBracketsContent[0] &&
    tokenBracketsContent[0].toLowerCase();
  const isGalaChainToken =
    tokenBracketsContentLowercase === '[gyri]' ||
    tokenBracketsContentLowercase === '[galachain]';

  if (
    state.profile.user.walletExists ||
    state.profile.user.walletConnected ||
    (isInventoryRoute && isGalaChainToken)
  ) {
    return;
  } else {
    await store.dispatch('profile/getUserData');
    if (
      !state.profile.user.walletExists &&
      !state.profile.user.walletConnected &&
      !state.profile.user.hasGyriPassphrase
    ) {
      redirect('/');
    }
  }
}
