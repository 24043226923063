


































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import GalaLogo from '../UIComponents/GalaLogo.vue';
import { setRedirectAfterLogin } from '~/utils/redirectAfterLogin';

@Component({
  components: {
    GalaLogo,
  },
})
export default class MobileNavigationDrawer extends Vue {
  @Prop(Boolean) drawerControl!: boolean;
  @Prop() tabs!: {
    title: string;
    name: string;
    to?: string;
    requirement: boolean;
    mobile?: boolean;
    target?: string;
  }[];
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  @Watch('drawerControl')
  onDialogChange(val: boolean) {
    val === false && this.$emit('close');
  }

  goToLoginService() {
    setRedirectAfterLogin(this.$route.fullPath);
    return this.$auth.loginWithRedirect({
      authorizationParams: { prompt: 'login' },
    });
  }

  goToRegisterService() {
    setRedirectAfterLogin(this.$route.fullPath);
    return this.$auth.loginWithRedirect({
      authorizationParams: { prompt: 'login', screen_hint: 'signup' },
    });
  }
}
