
























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  filters: {},
})
export default class RequirePasscode extends Vue {
  @Prop({ type: String, default: 'Complete' }) readonly buttonText!: string;
  @Prop(Function) readonly action!: (passcode: string) => any;
  @Prop(String) readonly subheading!: string;
  @Prop(Boolean) readonly hideHeading!: boolean;
  @Prop({ type: Array, default: () => [] }) readonly rules!: any[];
  @Prop(String) readonly customLoadingVideoUrl?: string;
  @Prop({ type: Boolean, default: false })
  readonly useOldActionHandling!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly standAloneFormatting!: boolean;

  loading = false;
  encryptionPasscode = '';
  showPass = false;

  get buttonDisabled() {
    return !this.checkIfInputIsValid();
  }

  get customLoadingVideoType() {
    const extension = this.customLoadingVideoUrl?.split('.').pop();
    if (extension === 'mp4') {
      return 'video/mp4';
    } else if (extension === 'webm') {
      return 'video/webm';
    } else if (extension === 'ogg') {
      return 'video/ogg';
    }
  }

  extractUserFacingGQLErrorMessage(err: any) {
    const fromUserFacingError = err?.graphQLErrors?.find(
      (e: any) => e?.extensions?.exception?.name === 'UserFacingError',
    )?.message;

    const fromUserFacingMessage = err?.graphQLErrors
      ?.map((e: any) => e?.extensions?.userFacingMessage)
      .find(Boolean);

    return fromUserFacingError ?? fromUserFacingMessage;
  }

  checkIfInputIsValid() {
    if (!this.encryptionPasscode) {
      return false;
    }
    if (this.rules.length) {
      return this.rules.every(rule => {
        return rule(this.encryptionPasscode) === true;
      });
    }
    return true;
  }

  performAction() {
    if (this.useOldActionHandling) {
      return this.performActionV1();
    }

    // This new handling should be easier to use...
    // but too many things rely on the old handling
    // in different ways, so keeping it for now for
    // legacy code.
    return this.performActionV2();
  }

  async performActionV2() {
    if (!this.encryptionPasscode || !this.checkIfInputIsValid()) {
      this.$emit('action-error', {
        message: this.$t('components.wallet.requirePasscode.requirePasscode'),
      });
      this.clearInput();
      return;
    }

    try {
      this.loading = true;
      const results = await this.action(this.encryptionPasscode);
      this.$emit('action-complete', results);

      this.loading = false;
      this.clearInput();
    } catch (error) {
      console.warn(error);
      this.loading = false;
      const userFacingErrorMessage =
        this.extractUserFacingGQLErrorMessage(error) ?? error.message;
      this.$emit('action-error', {
        message: userFacingErrorMessage,
        error,
      });
      this.clearInput();
    }
  }

  async performActionV1() {
    if (!this.encryptionPasscode || !this.checkIfInputIsValid()) {
      this.$emit('action-complete', {
        success: false,
        message: 'You must enter a valid passcode',
      });
      this.clearInput();
      return;
    }
    try {
      this.loading = true;
      const results = await this.action(this.encryptionPasscode);
      if (results) {
        this.$emit('action-complete', { success: true, results: results.data });
      } else {
        this.$emit('action-complete', { success: false, message: 'Canceled' });
      }

      this.loading = false;
      this.clearInput();
    } catch (error) {
      console.warn(error);
      this.$sentry.captureException(error);
      this.loading = false;
      this.$emit('action-complete', {
        success: false,
        message:
          this.extractUserFacingGQLErrorMessage(error) ??
          'Something went wrong',
      });
      this.clearInput();
    }
  }

  clearInput() {
    this.encryptionPasscode = '';
  }
}
