





















































































































import '@fortawesome/fontawesome-free/css/all.css';
import { Component, Vue } from 'vue-property-decorator';
import { State, Mutation, Action, Getter } from 'vuex-class';
import _debounce from 'lodash.debounce';
import _mapkeys from 'lodash.mapkeys';
import { ICurrentPage } from '~/store/types';
import { IUser } from '~/store/profile/types';
import TopToolbar from '~/components/Home/TopToolbar.vue';
import GalaFooter from '~/components/Home/GalaFooter.vue';
import VerifyEmailAddress from '~/components/EmailVerification/VerifyEmailAddress.vue';
import AcceptTermsAndConditions from '~/components/Home/AcceptTermsAndConditions.vue';
import CreateWalletModalPrompt from '~/components/NewWallet/CreateWalletModalPrompt.vue';
import TwoFactorAuthPrompt from '~/components/ModalPrompts/TwoFactorAuthSetupPrompt.vue';
import { intlHead } from '~/utils/i18n_head';
import {
  captureReferrals,
  removeStoredReferralInfo,
  createReferral,
} from '~/utils/captureReferrals';
import GyriExchangeRewards from '~/components/Wallet/GyriExchangeRewards.vue';
import DiscordDialog from '~/components/Discord/DiscordDialog.vue';
import DiscordConnectingDialog from '~/components/Discord/DiscordConnectingDialog.vue';
import { ICmsContentState, ISanityPromoPack } from '~/store/cms_content/types';
import BannerNotification from '~/components/UIComponents/BannerNotification.vue';
import CoinflowPurchaseProtection from '~/components/Coinflow/CoinflowPurchaseProtection.vue';

@Component({
  head: intlHead(),
  components: {
    TopToolbar,
    GalaFooter,
    AcceptTermsAndConditions,
    VerifyEmailAddress,
    CreateWalletModalPrompt,
    TwoFactorAuthPrompt,
    GyriExchangeRewards,
    BannerNotification,
    DiscordDialog,
    DiscordConnectingDialog,
    CoinflowPurchaseProtection,
  },
})
export default class Dashboard extends Vue {
  @State(shareState => shareState.founderNodesOwned, { namespace: 'share' })
  readonly founderNodesOwned!: number;

  @State('snackbarSuccessText') snackbarSuccessText!: boolean;
  @State('snackbarErrorText') snackbarErrorText!: boolean;
  @State('showSuccessSnackbar') showSuccessSnackbarState!: boolean;
  @State('showErrorSnackbar') showErrorSnackbarState!: boolean;
  @State('currentPage') currentPage!: ICurrentPage;
  @State(state => state.showResetDialog)
  showResetDialog!: boolean;

  @State(profile => profile.activated, { namespace: 'profile' })
  activated!: boolean;
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;
  @State(profile => profile.user.goldMember, { namespace: 'profile' })
  readonly goldMember!: boolean;
  @State(share => share.nodesOwned, { namespace: 'share' })
  readonly nodesOwned!: number;
  @State(inventory => inventory.isFetchingWallets, { namespace: 'inventory' })
  isFetchingWallets!: IUser;
  @State((cmsContent: ICmsContentState) => cmsContent.promoPacks, {
    namespace: 'cmsContent',
  })
  readonly promoPacks!: ISanityPromoPack[];

  @State(cmsContent => cmsContent.topToolbarBanner, {
    namespace: 'cmsContent',
  })
  readonly topToolbarBanner!: any;

  @Action('fetchUserNodeLicenseCount', { namespace: 'share' })
  fetchUserNodeLicenseCount!: () => void;
  @Action('getWalletsData', { namespace: 'inventory' })
  private getWalletsData!: () => void;

  @Mutation('toggleSuccessSnackbar')
  private toggleSuccessSnackbar!: (args: any) => void;
  @Mutation('toggleErrorSnackbar')
  private toggleErrorSnackbar!: (args: any) => void;
  @Mutation('updateUser', { namespace: 'profile' })
  private updateUser!: (args: any) => void;
  @Mutation('togglePromoPackDialog')
  private togglePromoPackDialog!: (args: {
    active: boolean;
    promoPack: ISanityPromoPack;
  }) => void;
  @Getter('galaBalance', { namespace: 'inventory' })
  readonly galaBalance!: number;
  @Getter('suppressLegalDocuments')
  readonly suppressLegalDocuments!: boolean;

  showIphoneSnackbar = false;
  isTopToolbarBannerClosed = false;

  coinflowEnv: string = process.env.coinflowEnv || 'sandbox';
  coinflowMerchantId: string = process.env.coinflowMerchantId || 'galagames';

  get showSuccessSnackbar() {
    return this.showSuccessSnackbarState;
  }

  set showSuccessSnackbar(value) {
    this.toggleSuccessSnackbar(value);
  }

  get showErrorSnackbar() {
    return this.showErrorSnackbarState;
  }

  set showErrorSnackbar(value) {
    this.toggleErrorSnackbar(value);
  }

  get isPastOnboarding() {
    return this.$route.name !== 'register' && this.$route.name !== 'login';
  }

  get showWalletPrompt() {
    return (
      this.loggedIn &&
      !this.isFetchingWallets &&
      this.currentUser &&
      !this.currentUser.walletExists &&
      !this.currentUser.walletConnected &&
      !this.galaBalance &&
      this.$route.name !== 'connect-wallet'
    );
  }

  get featuredPromoPack() {
    return this.promoPacks.find(x => x.featured);
  }

  // Add condition to hide if pack claimed

  get showPromoPackPrompt() {
    return (
      !this.loggedIn &&
      this.featuredPromoPack?.enabled &&
      this.featuredPromoPack?.featured &&
      !['connect-wallet', 'verify-ip'].includes(String(this.$route.name))
    );
  }

  get showTopToolbarBanner() {
    const toolbarDismissedDateTime = localStorage.getItem(
      'topToolbarBannerDismissed',
    );
    let hasToolbarBeenDismissedToday;
    if (toolbarDismissedDateTime) {
      const dismissedDateTime = new Date(parseInt(toolbarDismissedDateTime));
      const oneDayEarlier = new Date(
        new Date().getTime() - 24 * 60 * 60 * 1000,
      );

      hasToolbarBeenDismissedToday = oneDayEarlier < dismissedDateTime;
    }

    return (
      !this.isTopToolbarBannerClosed &&
      this.topToolbarBanner?.enabled &&
      (!this.topToolbarBanner?.nodeOwnersOnly || this.founderNodesOwned) &&
      (!toolbarDismissedDateTime ||
        (toolbarDismissedDateTime && !hasToolbarBeenDismissedToday))
    );
  }

  get showTopToolbarBannerCloseBtn() {
    return this.topToolbarBanner?.allowUserToCloseBanner;
  }

  get discordConnected() {
    return !!this.currentUser.discordProfile;
  }

  handleGlobalClick(event: Event) {
    const supportedTargetTypes = ['BUTTON', 'CAROUSEL'];
    const htmlElement = event.target as HTMLElement | null;
    const closestTaggedElement = htmlElement?.parentElement?.closest(
      '[data-ua-interaction-target-type]',
    ) as HTMLElement | null;
    for (let element of [htmlElement, closestTaggedElement]) {
      if (!element) {
        continue;
      }

      const interactionTargetType =
        element?.dataset.uaInteractionTargetType ?? element.tagName;
      const interactionTargetId =
        element.dataset.uaInteractionTargetId ?? element.id;

      if (!interactionTargetType || !interactionTargetId) {
        continue;
      }

      if (!supportedTargetTypes.includes(interactionTargetType)) {
        if (element.dataset.uaInteractionTargetType) {
          console.warn(
            `Unsupported UA interaction target type: ${interactionTargetType}`,
          );
        }

        continue;
      }

      this.$ua.trackPress({
        interactionTargetId: interactionTargetId,
      });
    }
  }

  async created() {
    this.listenForWalletCreated();
    if (
      navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 &&
      !window.matchMedia('(display-mode: standalone)').matches &&
      !sessionStorage.getItem('hideIphonePopup') &&
      !this.$route.query.promo
    ) {
      this.showIphoneSnackbar = true;
    }

    document.addEventListener('click', this.handleGlobalClick);

    if (this.$route.query.promo) {
      await this.checkPromo(this.$route.query.promo as string);
    }
  }

  destroyed() {
    document.removeEventListener('click', this.handleGlobalClick);
  }

  hideIphonePopup() {
    this.showIphoneSnackbar = false;
    sessionStorage.setItem('hideIphonePopup', 'true');
  }

  replaceOldUtmVariableStructure() {
    const existingUtmVariables = localStorage.getItem('utm-variables');
    if (existingUtmVariables) {
      const utmVariables = JSON.parse(existingUtmVariables);

      if (Array.isArray(utmVariables)) {
        const [utm_campaign, utm_medium, utm_source, utm_term] = utmVariables;

        const newUtmVariables = {
          utm_campaign,
          utm_medium,
          utm_source,
          utm_term,
        };

        localStorage.setItem('utm-variables', JSON.stringify(newUtmVariables));
      } else if (
        typeof utmVariables.campaign === 'string' ||
        typeof utmVariables.medium === 'string' ||
        typeof utmVariables.source === 'string' ||
        typeof utmVariables.term === 'string'
      ) {
        const newUtmVariables = {
          utm_campaign:
            utmVariables.campaign || utmVariables.utm_campaign || '',
          utm_medium: utmVariables.medium || utmVariables.utm_medium || '',
          utm_source: utmVariables.source || utmVariables.utm_source || '',
          utm_term: utmVariables.term || utmVariables.utm_term || '',
        };
        localStorage.setItem('utm-variables', JSON.stringify(newUtmVariables));
      }
    }
  }

  async mounted() {
    this.replaceOldUtmVariableStructure();
    captureReferrals(this.$route.query);
    if (this.$route.name && this.$route.name !== 'inventory-symbol') {
      this.getWalletsData();
    }
    if (this.currentUser.loggedIn) {
      this.fetchUserNodeLicenseCount();
      await createReferral(this.$apolloProvider.defaultClient);
      removeStoredReferralInfo();
    }
  }

  listenForWalletCreated() {
    window.addEventListener('storage', e => {
      if (e.key === 'walletCreated') {
        this.updateUser({ walletExists: true });
        this.getWalletsData();
      }
    });
  }

  checkPromo(id: string) {
    const promoPack = this.promoPacks.find(x => x.slug === id);
    if (promoPack) {
      this.togglePromoPackDialog({ active: true, promoPack });
    }
  }
}
