





























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import NewWallet from '~/components/NewWallet/NewWallet.vue';
import CaptureNameModal from '~/components/NewWallet/CaptureNameModal.vue';
import { IUser } from '~/store/profile/types';

@Component({ components: { NewWallet, CaptureNameModal } })
export default class WalletCreationModalFlow extends Vue {
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  @Prop({ type: Boolean, default: false })
  value!: boolean;
  @Prop({ type: Boolean, default: false })
  readonly settingGyriPassphrase!: boolean;

  firstNameState = '';
  lastNameState = '';

  @Watch('value')
  onValueChanged() {
    this.firstNameState = '';
    this.lastNameState = '';
  }

  handleFullNameSubmitted(firstName: string, lastName: string) {
    this.firstNameState = firstName;
    this.lastNameState = lastName;
  }

  handleCloseRequested() {
    this.$emit('input', false);
  }

  get firstName() {
    return this.currentUser.fullNameNotProvided
      ? this.firstNameState
      : this.currentUser.firstName;
  }

  get lastName() {
    return this.currentUser.fullNameNotProvided
      ? this.lastNameState
      : this.currentUser.lastName;
  }

  get hasFullName() {
    return this.firstName && this.lastName;
  }

  get showWalletCreationModal() {
    return this.value && this.hasFullName;
  }

  get showNameCaptureModal() {
    return this.value && !this.hasFullName;
  }
}
