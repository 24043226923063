import { LocaleObject } from '@nuxtjs/i18n';

export const numericFormatOptions = [
  {
    jsCode: 'en',
    displayTextKey: 'components.numberFormatSelector.usShort',
    flagCode: 'us',
    defaultForUsersOlderThan: new Date('2023-08-30T00:00:00.000Z'),
    defaultForOthers: true,
  },
  {
    // We call it "international" formatting but it's actually German
    // (and lots of other countries are the same so we use it as a catchall)
    // The truth is that there are definitely more than 2 formats,
    // but we're only supporting 2 for now: US and "international"
    jsCode: 'de',
    displayTextKey: 'components.numberFormatSelector.internationalShort',
    flagCode: 'un',
    defaultForCountryCodeIn: [
      '419',
      'AR',
      'AT',
      'BE',
      'BA',
      'BR',
      'BG',
      'CA',
      'CL',
      'CO',
      'HR',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GE',
      'GR',
      'HU',
      'ID',
      'IT',
      'NE',
      'NO',
      'PL',
      'PT',
      'RO',
      'RU',
      'RS',
      'SK',
      'SI',
      'ZA',
      'ES',
      'CH',
      'SE',
      'TR',
      'UA',
      'UY',
    ],
  },
];

export function getDefaultNumericFormatCode(accountCreationDate?: Date) {
  const countryCode = navigator.language.split('-')[1];

  const defaultOption =
    numericFormatOptions.find(option => {
      if (
        option.defaultForUsersOlderThan &&
        accountCreationDate &&
        accountCreationDate < option.defaultForUsersOlderThan
      ) {
        return true;
      }

      if (option.defaultForCountryCodeIn?.includes(countryCode)) {
        return true;
      }

      return false;
    }) ?? numericFormatOptions.find(f => f.defaultForOthers);

  return defaultOption?.jsCode;
}

export function availableLocales(locales: LocaleObject[]) {
  for (const locale of locales) {
    switch (locale.code) {
      case 'en':
        locale.flagCode = 'us';
        locale.displayText = 'EN';
        break;
      case 'ko':
        locale.flagCode = 'kr';
        locale.displayText = 'KR';
        break;
      case 'ja':
        locale.flagCode = 'jp';
        locale.displayText = 'JP';
        break;
      case 'zh':
        locale.flagCode = 'cn';
        locale.displayText = 'CH';
        break;
    }
  }

  return locales;
}

export function getCurrentLocale(localeInfo: {
  localeCookie: string | undefined;
  browserLocale: string | undefined;
  locales: any;
}) {
  const { localeCookie, browserLocale, locales } = localeInfo;
  if (localeCookie) {
    for (const locale of availableLocales(locales)) {
      if (locale.code === localeCookie) {
        return locale;
      }
    }
  }

  if (browserLocale) {
    for (const locale of availableLocales(locales)) {
      if (locale.code === browserLocale) {
        return locale;
      }
    }
  }

  return {
    code: 'en',
    flagCode: 'us',
    file: 'en_US.json',
    name: 'English',
    displayText: 'EN',
  };
}
