import Sanity, { SanityClient } from '@sanity/client';
import {
  IBlogBlockRule,
  ICarouselBanner,
  ISimpleBanner,
  ICampaignWelcomeModal,
  ISanityTopToolbarBanner,
  ISanityGalaLauncher,
  ISanityGalaLauncherV2,
  ISanityPromoPack,
  IInfoArticleLinkList,
  IGameCardCarousel,
  IPromoLandingPage,
} from '~/store/cms_content/types';
import { GamePlatform, IDevStatusAndProgress } from '~/store/games/types';

export interface IRequiredGameItem {
  asset: string;
  baseId: string;
  contractAddress: string;
  itemName: string;
}

export interface ISanityGame {
  name: string;
  displayName: string;
  isReleased: boolean;
  longDescription: string;
  developer: string;
  gamePublisher: string;
  genre: string;
  developmentStatus: string;
  websiteLink: string;
  discordLink: string;
  facebookLink: string;
  instagramLink: string;
  twitterLink: string;
  youtubeLink: string;
  platform: GamePlatform[];
  titleImage: string;
  trailer: string;
  trailerThumbnail: string;
  carouselVideos: string[];
  carouselImages: string[];
  gamePageHeaderLogo: string;
  gamePageBackground: string;
  aboutUsImage: string;
  showGamePage: boolean;
  playLink: string;
  requireAuthToPlay: boolean;
  route: string;
  collection: string;
  codeName: string;
  usesGalaChain: boolean;
  linkImage: string;
  installerUrlWin: string;
  installerUrlMac: string;
  isWinReleased: boolean;
  isMacReleased: boolean;
  isReleasedInternally: boolean;
  inventoryDisclaimer: string;
  latestWindowsVersion: string;
  latestMacVersion: string;
  showActivePlayers: boolean;
  timePlayerIsActive: number;
  activePlayerCountInDataBricks: boolean;
  hasItemRequiredToPlay: boolean;
  requiredItemIds: IRequiredGameItem[];
  requiredItemUrl: string;
  hasApkFile?: boolean;
  apkLabelText?: string;
  apkLinkIcon?: boolean;
  apkLinkText?: string;
  apkLink?: string;
  hasIOSDownload?: boolean;
  iOSTestflightLabelText?: string;
  iOSTestflightLinkIcon?: boolean;
  iOSTestflightLinkText?: string;
  iOSDownloadLink?: string;
  customGyriGachaLoadingVideo?: string;
  tenantId: string;
  totallyBlockedInLocations?: string[];
  gameIFrameCustomCss?: {
    code: string;
  };
  gameIFrameWrapperDivCustomCss?: {
    code: string;
  };
  mainGenres?: string[];
  secondaryGenres?: string[];
  customDevStatus?: string;
  devStatusAndProgress?: IDevStatusAndProgress;
  usePlayButtonRedirect?: boolean;
  playButtonRedirectLink?: string;
  storeFilterLinkRoute?: string;
  isFreeToPlay?: boolean;
}

export interface ISanityHomepageTakeOver {
  name: string;
  enabled: boolean;
  logo: string;
  startDate: string;
  endDate: string;
  bgImageDesktop: string;
  bgImageMobile: string;
  noVideoMobile: boolean;
  bgVideo: string;
  ctaColor: string;
  ctaLink: string;
  ctaText: string;
  ctaTextColor: string;
  customFont: string;
  customStyles: string;
  glowColor: string;
  headline: string;
  subhead: string;
  textColor: string;
  textShadow: boolean;
  blockedInLocations: string[];
}

// Sanity.io // SanityCMS
class SanityService {
  client: SanityClient;
  private readonly queryCache: Record<
    string,
    Promise<unknown> | undefined
  > = {};

  constructor() {
    this.client = Sanity({
      projectId: process.env.sanityProjectId,
      dataset: process.env.sanityDataset,
      apiVersion: process.env.sanityApiVersion, // use current UTC date - see "specifying API version"!
      token: process.env.sanityToken, // or leave blank for unauthenticated usage
      useCdn: true, // `false` if you want to ensure fresh data
    });
  }

  async handleFetch(query: string, cacheKey: string) {
    if (this.queryCache[cacheKey]) {
      return this.queryCache[cacheKey];
    }

    this.queryCache[cacheKey] = this.client.fetch(query);

    try {
      return await this.queryCache[cacheKey];
    } catch (err) {
      delete this.queryCache[cacheKey];
      throw err;
    }
  }

  async getInventoryCollections(locale: string) {
    const query = `*[_type == "inventoryCollection"] { name, "inventoryImage":inventoryImage.asset->url, "displayName": coalesce(displayName.${locale}, displayName.en) }`;
    const results = await this.handleFetch(query, query);

    return results as Array<{
      name: string;
      inventoryImage: string;
      displayName: string;
    }>;
  }

  async getGalaLauncherInformation(
    locale: string,
  ): Promise<ISanityGalaLauncher> {
    const query = `*[_type == "galaLauncher"][0] { 
      version,
      installerUrlWin,
      installerUrlMac,
      gameServerEndpointWin,
      gameServerEndpointMac,
      upgradeFixVersion
    }`;
    const result = await this.handleFetch(query, query);
    return result as ISanityGalaLauncher;
  }

  async getGalaLauncherV2Information(
    locale: string,
  ): Promise<ISanityGalaLauncherV2> {
    const query = `*[_type == "galaLauncherV2"][0] { 
      version,
      installerUrlWin,
      installerUrlMac,
      gameServerEndpointWin,
      gameServerEndpointMac
    }`;
    const result = await this.handleFetch(query, query);
    return result as ISanityGalaLauncherV2;
  }

  async getPromoPackInformation(locale: string): Promise<ISanityPromoPack[]> {
    const query = `*[_type == "PromoPack" && enabled == true] { 
      "slug": slug.current,
      enabled,
      featured,
      "mobileAlert": coalesce(alertBarMobileText.${locale}, alertBarMobileText.en),
      "desktopAlert": coalesce(alertBarDesktopText.${locale}, alertBarDesktopText.en),
      displayInterval,
      promoItems[] {
        showCondition,
        "headline": coalesce(headline.${locale}, headline.en),
        "description": coalesce(description.${locale}, description.en),
        "stepsToRedeem": coalesce(stepsToRedeem[].${locale},stepsToRedeem[].en),
        hasMoreInfo,
        "moreInfoText": coalesce(moreInfoText.${locale},moreInfoText.en),
        "buttonText": coalesce(buttonText.${locale},buttonText.en),
        buttonAction,
        buttonLink,
        buttonTarget,
        exchangeRevealVideo,
        "promoImage": promoImage.asset->url,
        "backgroundImageMobile": backgroundImageMobile.asset->url,
        "backgroundImageDesktop": backgroundImageDesktop.asset->url,
        customStyles,
      }
    }`;
    const result = await this.handleFetch(query, query);
    return result as ISanityPromoPack[];
  }

  async getPromoLandingPage(
    locale: string,
    slug: string,
  ): Promise<IPromoLandingPage[]> {
    const query = `*[_type == "promoLandingPage" && enabled == true && slug.current == "${slug}"] { 
      "slug": slug.current,
      enabled,
      "mainHeaderText": coalesce(mainHeaderText.${locale}, mainHeaderText.en),
      "mainHeaderIcon": mainHeaderIcon.asset->url,
      "secondaryHeaderText": coalesce(secondaryHeaderText.${locale}, secondaryHeaderText.en),
      "headerLogo": headerLogo.asset->url,
      "headerLogoAlt": coalesce(headerLogoAlt.${locale}, headerLogoAlt.en),
      "headerImage": headerImage.asset->url,
      "headerHeroImageAlt": coalesce(headerHeroImageAlt.${locale}, headerHeroImageAlt.en),
      "headerHeroVideo": headerHeroVideo.asset->url,
      "headerHeroVideoAlt": headerHeroVideoAlt.asset->url,
      landingPageSections[] {
        "sectionLabelPrefix": coalesce(sectionLabelPrefix.${locale}, sectionLabelPrefix.en),
        "sectionLabel": coalesce(sectionLabel.${locale}, sectionLabel.en),
        singleCta,
        twoCtas,
        isCtaCardList,
        firstCtaData{
          "ctaHeader": coalesce(ctaHeader.${locale}, ctaHeader.en),
          "headerColorText": coalesce(headerColorText.${locale}, headerColorText.en),
          "ctaText": coalesce(ctaText.${locale}, ctaText.en),
          showSignUpButton,
          showCustomLinkButton,
          ctaButtonLink,
          "ctaButtonText": coalesce(ctaButtonText.${locale}, ctaButtonText.en),
          "buttonIcon": buttonIcon.asset->url,
          buttonColor,
          infoLink,
          "infoLinkText": coalesce(infoLinkText.${locale}, infoLinkText.en),
          sectionActionWalletProvider,
          showAnimatedImages,
          "animatedImage1": animatedImage1.asset->url,
          "animatedImage1Alt": coalesce(animatedImage1Alt.${locale}, animatedImage1Alt.en),
          "animatedImage2": animatedImage2.asset->url,
          "animatedImage2Alt": coalesce(animatedImage2Alt.${locale}, animatedImage2Alt.en),
        },
        secondCtaData{
          "ctaHeader": coalesce(ctaHeader.${locale}, ctaHeader.en),
          "headerColorText": coalesce(headerColorText.${locale}, headerColorText.en),
          "ctaText": coalesce(ctaText.${locale}, ctaText.en),
          showSignUpButton,
          showCustomLinkButton,
          ctaButtonLink,
          "ctaButtonText": coalesce(ctaButtonText.${locale}, ctaButtonText.en),
          "buttonIcon": buttonIcon.asset->url,
          buttonColor,
          infoLink,
          "infoLinkText": coalesce(infoLinkText.${locale}, infoLinkText.en),
          sectionActionWalletProvider,
        },
        ctaCards[] {
          "ctaCardImage": ctaCardImage.asset->url,
          "ctaCardImageAlt": coalesce(ctaCardImageAlt.${locale}, ctaCardImageAlt.en),
          "imageOverlayText": coalesce(imageOverlayText.${locale}, imageOverlayText.en),
          accentColor,
          ctaCardButtonLink,
          "ctaCardButtonText": coalesce(ctaCardButtonText.${locale}, ctaCardButtonText.en),
          additionalLink,
          "additionalLinkText": coalesce(additionalLinkText.${locale}, additionalLinkText.en),
          "accentHeader": coalesce(accentHeader.${locale}, accentHeader.en),
          "accentHeaderLabel": coalesce(accentHeaderLabel.${locale}, accentHeaderLabel.en),
          "mainHeader": coalesce(mainHeader.${locale}, mainHeader.en),
          "descriptionText": coalesce(descriptionText.${locale}, descriptionText.en),
        }
      }
    }`;
    const result = await this.handleFetch(query, query);
    return result as IPromoLandingPage[];
  }

  async getTopToolbarBanner(locale: string): Promise<ISanityTopToolbarBanner> {
    const query = `*[_type == "TopToolbarBanner"][0] { 
      enabled,
      nodeOwnersOnly,
      allowUserToCloseBanner,
      "toolbarBannerMobileText": coalesce(toolbarBannerMobileText.${locale}, toolbarBannerMobileText.en),
      "toolbarBannerDesktopText": coalesce(toolbarBannerDesktopText.${locale}, toolbarBannerDesktopText.en),
      url,
      "toolbarBannerLinkText": coalesce(toolbarBannerLinkText.${locale}, toolbarBannerLinkText.en),
    }`;
    const result = await this.handleFetch(query, query);
    return result as ISanityTopToolbarBanner;
  }

  async getSimpleBanners(locale: string): Promise<ISimpleBanner[]> {
    const now = new Date().toISOString();

    const banners = (await this.handleFetch(
      `*[
      _type == "simpleBanner" 
      && enabled == true
      && (startDate == null || dateTime('${now}') > dateTime(startDate))
      && (endDate == null ||   dateTime('${now}') < dateTime(endDate))
    ] {
      name,
      "alt": coalesce(alt.${locale}, alt.en),
      "desktopBannerImage":desktopBannerImage.asset->url,
      "mobileBannerImage":mobileBannerImage.asset->url,
      internalRoute,
      showOnMain,
      showOnStore,
      blockedInLocations,
      order
    } | order(order asc)`,
      `simple_banners_${locale}`,
    )) as ISimpleBanner[];

    return banners;
  }

  async getBlogBlockRules(
    ipCountryKey: string,
    ipRegionKey: string,
  ): Promise<IBlogBlockRule[]> {
    const rules = (await this.handleFetch(
      `*[
      _type == "blogBlockedKeywords"
      && (('${ipCountryKey}' in blockedInLocations) || ('${ipRegionKey}' in blockedInLocations) || blockedInLocations == null)
    ] {
      keywords
    }`,
      `blog_block_rules_${ipRegionKey}`,
    )) as IBlogBlockRule[];

    return rules;
  }

  async getHomepageTakeOvers(
    locale: string,
    ipCountryKey: string,
    ipRegionKey: string,
  ): Promise<ISanityHomepageTakeOver> {
    const now = new Date().toISOString();
    const homepageTakeover = (await this.handleFetch(
      `*[
      _type == "HomepageTakeover" 
      && enabled == true
      && (startDate == null || dateTime('${now}') > dateTime(startDate))
      && (endDate == null ||   dateTime('${now}') < dateTime(endDate))
      && !(('${ipCountryKey}' in blockedInLocations) || ('${ipRegionKey}' in blockedInLocations))
    ][0] {
      name,
      "logo":logo.asset->url,
      startDate,
      endDate,
      "bgImageDesktop":bgImageDesktop.asset->url,
      "bgImageMobile":bgImageMobile.asset->url,
      "bgVideo":bgVideo.asset->url,
      noVideoMobile,
      "ctaColor":ctaColor.hex,
      ctaLink,
      "ctaText":coalesce(ctaText.${locale}, ctaText.en),
      "ctaTextColor":ctaTextColor.hex,
      customFont,
      customStyles,
      "glowColor":glowColor.hex,
      "headline":coalesce(headline.${locale}, headline.en),
      "subhead":coalesce(subhead.${locale}, subhead.en),
      "textColor":textColor.hex,
      textShadow,
      blockedInLocations
    }`,
      `homepage_takeovers_${locale}_${ipRegionKey}`,
    )) as ISanityHomepageTakeOver;

    return homepageTakeover;
  }

  async getCarouselBanners(locale: string) {
    const now = new Date().toISOString();

    const banners = (await this.handleFetch(
      `*[
      _type == "banner" 
      && enabled == true
      && (startDate == null || dateTime('${now}') > dateTime(startDate))
      && (endDate == null || dateTime('${now}') < dateTime(endDate))
    ] {
      name,
      "title": coalesce(title.${locale}, title.en),
      "description": coalesce(description.${locale}, description.en),
      "tagline": coalesce(tagline.${locale}, tagline.en),
      size,
      bannerImageClass,
      "bannerImageMin":bannerImageMin.asset->url,
      "bannerImage":bannerImage.asset->url,
      "learnMoreText": coalesce(learnMoreText.${locale}, learnMoreText.en),
      learnMoreLink,
      playLink,
      order,
      whenUnauthenticated,
      blockedInLocations
    } | order(order asc)`,
      `carousel_banners_${locale}`,
    )) as ICarouselBanner[];

    return banners;
  }

  async getGames(locale: string) {
    const games = (await this.handleFetch(
      `*[
      _type == "game" 
    ] {
      name,
      "displayName": coalesce(displayName.${locale}, displayName.en),
      "longDescription": coalesce(longDescription.${locale}, longDescription.en),
      isReleased,
      isWinReleased,
      isMacReleased,
      developer,
      gamePublisher,
      "genre": coalesce(genre.${locale}, genre.en),
      mainGenres,
      secondaryGenres,
      "developmentStatus": coalesce(developmentStatus.${locale}, developmentStatus.en),
      websiteLink,
      discordLink,
      facebookLink,
      instagramLink,
      twitterLink,
      youtubeLink,
      platform,
      "linkImage": linkImage.asset->url,
      "trailer": trailer.asset->url,
      "trailerThumbnail": trailerThumbnail.asset->url,
      "carouselVideos": carouselVideos[].asset->url,
      "carouselImages": carouselImages[].asset->url,
      "gamePageHeaderLogo": gamePageHeaderLogo.asset->url,
      "gamePageBackground": gamePageBackground.asset->url,
      "aboutUsImage": aboutUsImage.asset->url,
      usePlayButtonRedirect,
      isFreeToPlay,
      playButtonRedirectLink,
      showGamePage,
      playLink,
      hasItemRequiredToPlay,
      requiredItemIds,
      requiredItemUrl,
      requireAuthToPlay,
      route,
      collection,
      codeName,
      usesGalaChain,
      hasRestrictionsToPlay,
      restrictionId,
      isReleasedInternally,
      "inventoryDisclaimer": coalesce(inventoryDisclaimer.${locale}, inventoryDisclaimer.en),
      "latestWindowsVersion": coalesce(latestWindowsVersion, latestVersion),
      latestMacVersion,
      installerUrlWin,
      installerUrlMac,
      showActivePlayers,
      timePlayerIsActive,
      activePlayerCountInDataBricks,
      hasApkFile,
      "apkLabelText": coalesce(apkLabelText.${locale}, apkLabelText.en),
      apkLinkIcon,
      "apkLinkText": coalesce(apkLinkText.${locale}, apkLinkText.en),
      apkLink,
      hasIOSDownload,
      "iOSTestflightLabelText": coalesce(iOSTestflightLabelText.${locale}, iOSTestflightLabelText.en),
      iOSTestflightLinkIcon,
      "iOSTestflightLinkText": coalesce(iOSTestflightLinkText.${locale}, iOSTestflightLinkText.en),
      iOSDownloadLink,
      order,
      customGyriGachaLoadingVideo,
      tenantId,
      totallyBlockedInLocations,
      totallyBlockedInLocations,
      gameIFrameCustomCss,
      storeFilterLinkRoute,
      gameIFrameWrapperDivCustomCss,
      "cardCarouselPromoImage": cardCarouselPromoImage.asset->url,
      "cardCarouselPromoLogo": cardCarouselPromoLogo.asset->url,
      "customDevStatus": coalesce(customDevStatus.${locale}, customDevStatus.en),
      devStatusAndProgress
    } | order(order asc)`,
      `games_${locale}`,
    )) as ISanityGame[];

    return games;
  }

  async getCampaignWelcomeModals(
    locale: string,
    ipCountryKey: string,
    ipRegionKey: string,
  ) {
    const modals = await this.handleFetch(
      `*[
      _type == "campaignWelcomeModal"
      && enabled == true
      && !(('${ipCountryKey}' in blockedInLocations) || ('${ipRegionKey}' in blockedInLocations))
    ] {
      campaign,
      content[] {
        ...,
        asset->{
          ...,
          "key": _id
        }
      }
    }`,
      `campaign_welcome_modals_${locale}`,
    );

    return modals as ICampaignWelcomeModal[];
  }

  async getInfoArticleList(locale: string): Promise<IInfoArticleLinkList> {
    const now = new Date().toISOString();
    const infoArticleLinkList = (await this.handleFetch(
      `*[
      _type == "infoArticleLinkList" 
      && (startDate == null || dateTime('${now}') > dateTime(startDate))
      && (endDate == null ||   dateTime('${now}') < dateTime(endDate))
      && enabled == true
    ] | order(startDate desc)[0] {
      articleLinks[]-> {
        "articleTitle": coalesce(articleTitle.${locale}, articleTitle.en),
        "alt": coalesce(alt.${locale}, alt.en),
        articleUrl,
        "articleImage":articleImage.asset->url,
        startDate,
        endDate,
        enabled
      }
    }`,
      `info_article_link_list_${locale}`,
    )) as IInfoArticleLinkList;

    return infoArticleLinkList;
  }

  async getGameCardCarousel(locale: string): Promise<IGameCardCarousel> {
    const now = new Date().toISOString();
    const gameCardCarousel = (await this.handleFetch(
      `*[
      _type == "gameCardCarouselList" 
      && (startDate == null || dateTime('${now}') > dateTime(startDate))
      && (endDate == null ||   dateTime('${now}') < dateTime(endDate))
    ] | order(startDate desc)[0] {
      gameCards[]-> {
        collection
      }
    }`,
      `game_card_carousel_list_${locale}`,
    )) as IGameCardCarousel;

    return gameCardCarousel;
  }
}

export default new SanityService();
