



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation, State, Action, Getter } from 'vuex-class';

@Component({})
export default class CustomExchangeRevealItemCard extends Vue {
  @Prop(String) image!: string;
  @Prop(String) item!: string;
  @Prop(String) ind!: string;

  @State(customReveal => customReveal, { namespace: 'customReveal' })
  customReveal!: any;

  @Getter('shouldSkipAnimation', { namespace: 'customReveal' })
  shouldSkipAnimation!: boolean;

  @Action('loadCard', { namespace: 'customReveal' })
  private loadCard!: (payload: string) => void;

  @Action('revealCard', { namespace: 'customReveal' })
  private revealCard!: (payload: string) => void;

  get skipAnimation() {
    return this.shouldSkipAnimation;
  }

  created() {
    this.$watch('shouldSkipAnimation', (newVal: object, oldVal: object) => {
      this.$nextTick(() => {
        if (this.shouldSkipAnimation) {
          this.loadCard(this.ind);
          this.revealCard(this.ind);
        }
      });
    });
  }

  handleAnimationEnd(e: any) {
    if (!this.shouldSkipAnimation) {
      if (e.animationName.indexOf('cardSettle') !== -1) {
        e.target.classList.add('loaded');
        e.target.addEventListener('click', this.handleCardClick);
        this.loadCard(this.ind);
      }
    }
  }

  handleCardClick(e: any) {
    e.stopPropagation();
    e.currentTarget.classList.add('revealed');
    this.revealCard(this.ind);
  }
}
