import { NuxtI18nInstance } from '@nuxtjs/i18n';

const chains = [
  {
    network: 'ETH_TREASURE_CHEST',
    contractType: undefined,
    chainDisplayNameWithContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithoutContractType: '',
    textColor: '#AD8138',
    itemNameTextWeight: undefined,
    icon: require('@/assets/icons/icon_treasureChest_closed.png'),
    iconAltText: 'Treasure Chest Icon',
    bumpUpIcon: false,
    hasSendGasFees: undefined, // sending not supported
    hasExportGasFees: true,
  },
  {
    network: 'GYRI_TREASURE_CHEST',
    contractType: undefined,
    chainDisplayNameWithContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.treasureChest',
    chainDisplayNameWithoutContractType: '',
    textColor: '#AD8138',
    itemNameTextWeight: undefined,
    icon: require('@/assets/icons/treasure-chest-gray.svg'),
    iconAltText: 'Treasure Chest Icon',
    bumpUpIcon: false,
    hasSendGasFees: undefined, // sending not supported
    hasExportGasFees: true,
  },
  {
    network: 'ETHEREUM',
    contractType: 'erc1155',
    chainDisplayNameWithContractTypeKey: 'common.transactions.ethErc1155',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    textColor: '#C9B3F5',
    itemNameTextWeight: undefined,
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum ERC-1155 Icon',
    bumpUpIcon: true,
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
  },
  {
    network: 'ETHEREUM',
    contractType: 'erc20',
    chainDisplayNameWithContractTypeKey: 'common.transactions.ethErc20',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    textColor: '#C4DCEB',
    itemNameTextWeight: undefined,
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum ERC-20 Icon',
    bumpUpIcon: true,
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
  },
  {
    network: 'ETHEREUM',
    contractType: 'erc721',
    chainDisplayNameWithContractTypeKey: 'common.transactions.ethErc721',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    textColor: '#C4DCEB',
    itemNameTextWeight: undefined,
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum ERC-721 Icon',
    bumpUpIcon: true,
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
  },
  {
    network: 'ETHEREUM',
    contractType: undefined,
    chainDisplayNameWithContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.eth',
    chainDisplayNameWithoutContractType: '',
    textColor: '#C9B3F5',
    itemNameTextWeight: undefined,
    icon: require('@/assets/icons/icon_eth_inventory.png'),
    iconAltText: 'Ethereum Icon',
    bumpUpIcon: true,
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
  },
  {
    network: 'GYRI',
    contractType: undefined,
    chainDisplayNameWithContractTypeKey: 'common.transactions.gyri',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey: 'common.transactions.gyri',
    chainDisplayNameWithoutContractType: '',
    textColor: '#57A5ED',
    itemNameTextWeight: 700,
    icon: require('@/assets/icons/icon_gyri_inventory.png'),
    buttonIcon: require('@/assets/icons/icon_gyri_button.png'),
    iconAltText: 'GYRI Icon',
    bumpUpIcon: true,
    hasSendGasFees: true,
    hasExportGasFees: undefined, // exporting not supported yet
  },
  {
    network: 'GALACHAIN_ALLOWANCE',
    contractType: undefined,
    chainDisplayNameWithContractTypeKey:
      'common.transactions.galaChainAllowance',
    chainDisplayNameWithContractType: '',
    chainDisplayNameWithoutContractTypeKey:
      'common.transactions.galaChainAllowance',
    chainDisplayNameWithoutContractType: '',
    textColor: '#57A5ED',
    itemNameTextWeight: 700,
    icon: require('@/assets/icons/icon_gyri_inventory.png'),
    buttonIcon: require('@/assets/icons/icon_gyri_button.png'),
    iconAltText: 'GalaChain Icon',
    bumpUpIcon: true,
    hasSendGasFees: false,
    hasExportGasFees: undefined, // exporting not supported yet
    hasMintingGasFees: true,
  },
];

export function translateChainDisplayInfo(i18n: NuxtI18nInstance) {
  chains.forEach(chain => {
    chain.chainDisplayNameWithContractType = i18n.t(
      chain.chainDisplayNameWithContractTypeKey,
    ) as string;
    chain.chainDisplayNameWithoutContractType = i18n.t(
      chain.chainDisplayNameWithoutContractTypeKey,
    ) as string;
  });
}

export function getChainInfo(network: string, contractType?: string | null) {
  contractType = contractType || undefined;
  return chains.find(
    chain => chain.network === network && chain.contractType === contractType,
  );
}
