import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import PaymentPicker from '~/components/Wallet/PaymentPicker.vue';
import itemsForSaleByGala from '~/queries/gameItemProducts.gql';
import {
  IGameItemForSaleByGala,
  IGameItemProductPrice,
} from '~/store/games/types';
import { FiatProvider } from '../types/fiat-provider';

@Component({
  components: {
    PaymentPicker,
  },
})
export default class ProductPrices extends Vue {
  @State(profile => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;
  @State(profile => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;

  confirmDialog = false;
  successDialog = false;
  success = false;
  quantity = 1;
  item: IGameItemForSaleByGala | null = null;
  selectedPrice: IGameItemProductPrice & { imageUrl?: string } = {
    symbol: '',
    price: '0',
    basePrice: '0',
    usdPriceInCents: 0,
    usdBasePriceInCents: 0,
    creditCard: false,
  };
  selectedCredit: FiatProvider | null = null;
  transitionName = 'fade';

  get prices() {
    return this.item && this.item.prices ? this.item.prices : [];
  }

  get calculatedPrice() {
    const result = this.item ? +this.selectedPrice.price * this.quantity : 0;
    return result;
  }

  get soldOut() {
    if (this.item && this.item.baseId === 'town-node-license') {
      return this.item && this.item.qtyLeft <= 0;
    }

    return this.item && this.item.rarity && this.item.qtyLeft <= 0;
  }

  get discount() {
    if (this.item) {
      const discountAmount =
        1 - +this.selectedPrice.price / +this.selectedPrice.basePrice;
      return Math.max(discountAmount, 0);
    }
    return 0;
  }

  async fetchItem(baseId: string, priceAdjustments?: any) {
    const results = (await this.$apollo.query({
      query: itemsForSaleByGala,
      variables: {
        gameItemProductsInput: { baseId },
      },
      fetchPolicy: 'no-cache',
    })) as { data: { gameItemProducts: any[] } };

    if (
      results &&
      results.data &&
      results.data.gameItemProducts &&
      results.data.gameItemProducts.length
    ) {
      const newItem = { ...this.item, ...results.data.gameItemProducts[0] };
      if (newItem?.prices && priceAdjustments) {
        newItem.prices.forEach((price: any) => {
          const priceAdjustment = priceAdjustments.filter(
            (adjustment: any) => adjustment.token === price.symbol,
          );

          if (priceAdjustment.length) {
            if (
              priceAdjustment[0].usdPriceInCents &&
              priceAdjustment[0].price
            ) {
              price.usdPriceInCents = priceAdjustment[0].usdPriceInCents;
              price.price = priceAdjustment[0].price;
            }
          }
        });
      }

      this.item = newItem;
    }
  }

  handlePaymentMethodSelect(
    price: IGameItemProductPrice & { selectedCredit: FiatProvider },
  ) {
    this.selectedPrice = price;
    this.selectedCredit = price.selectedCredit;
  }

  handleContinueToBuy() {
    this.transitionName = 'slide-left';
    this.confirmDialog = true;
  }

  closeBuyConfirm() {
    this.transitionName = 'slide-right';
    this.confirmDialog = false;
  }

  @Watch('confirmDialog')
  onConfirmDialogChange(confirmDialog: boolean) {
    if (confirmDialog) {
      this.$ua.trackCheckoutFlowEnteredEvent({
        quantity: this.quantity,
        currency: this.selectedPrice?.symbol ?? 'unknown',
        productId: this.item?.baseId ?? 'unknown',
        unitPrice: Number(this.selectedPrice?.price ?? -1),
      });
    } else {
      this.$ua.trackCheckoutFlowExitedEvent({
        quantity: this.quantity,
        currency: this.selectedPrice?.symbol ?? 'unknown',
        productId: this.item?.baseId ?? 'unknown',
        unitPrice: Number(this.selectedPrice?.price ?? -1),
      });
    }
  }

  selectPriceBySymbol(symbol: string) {
    const selectedPrice = this.item?.prices.find(
      price =>
        price.symbol === symbol ||
        price.symbol === symbol.replace(/^CC-[a-zA-Z0-9]+$/, 'CC'),
    );
    if (selectedPrice) {
      this.selectedPrice = selectedPrice;
    }
  }

  @Watch('item', { immediate: true })
  onItemSet(item: IGameItemForSaleByGala) {
    if (item && item.prices && item.prices.length) {
      let initialCurrency = 'GALA';
      const { currency } = this.$route.query;
      const availableCurrencies = item.prices
        .reduce((acc: string[], price) => {
          return [...acc, price.symbol];
        }, [])
        .sort((a, b) => {
          if (a === 'CC-COINFLOW') {
            return -1;
          }
          if (b === 'CC-COINFLOW') {
            return 1;
          }
          return 0;
        });
      if (!availableCurrencies.includes('GALA')) {
        initialCurrency = availableCurrencies[0];
      }

      if (
        currency &&
        typeof currency === 'string' &&
        (availableCurrencies.includes(
          currency.replace(/^CC-[a-zA-Z0-9]+$/, 'CC'),
        ) ||
          availableCurrencies.includes(currency))
      ) {
        initialCurrency = currency;
      }
      this.selectPriceBySymbol(initialCurrency);
    }
  }
}
