import { ActionTree } from 'vuex';
import _differenceWith from 'lodash.differencewith';
import _partition from 'lodash.partition';
import {
  ICommitUpdateItemData,
  IExchangeTokenOptions,
  IInventoryState,
  IWallet,
} from './types';
import { IBaseGqlResponse, IRootState } from '../types';
import getWallets from '@/queries/wallets/wallets.gql';
import cryptocompareService from '~/services/cryptocompare.service';
import getUserItems from '~/queries/getUserItemsV3.gql';
import getUserTickets from '~/queries/getUserTickets.gql';
import tokenClaimFeesQuery from '~/queries/tokenClaimFees.gql';
import hasTransferLock from '~/queries/hasTransferLock.gql';
import sendGameItems from '~/mutations/sendGameItems.gql';
import claimTokensMutation from '~/mutations/claimTokens.gql';
import claimTokensV2Mutation from '~/mutations/claimTokensV2.gql';
import claimTokensWithExternalWalletMutation from '~/mutations/claimTokensWithExternalWallet.gql';
import swapEthTokenMutation from '~/mutations/swapEthToken.gql';
import exchangeEthTokenMutation from '~/mutations/exchangeToken.gql';
import exchangeGyriTokenMutation from '~/mutations/exchangeGyriToken.gql';
import fulfillGalaChainItemAllowanceMutation from '~/mutations/fulfillItemAllowance.gql';
import bridgeEthTokenMutation from '~/mutations/bridgeEthToken.gql';
import batchBridgeEthTokenMutation from '~/mutations/batchBridgeEthToken.gql';
import bridgeGyriTokenMutation from '~/mutations/bridgeGyriToken.gql';
import mockInventories from '~/mocks/inventory_items';
import { UserItem } from '~/types/user-items';
import { ISendItemActionPayload } from '~/types/vuex_payloads/send_item';
import {
  IBatchBridgeItemsActionPayload,
  IBridgeItemActionPayload,
} from '~/types/vuex_payloads/bridge_item';
import { IBridgeCurrencyActionPayload } from '~/types/vuex_payloads/bridge_currency';
import { ISwapCurrencyActionPayload } from '~/types/vuex_payloads/swap_currency';
import { IFulfillAllowanceActionPayload } from '~/types/vuex_payloads/fulfill_allowance';

const showFiatCurrencies = [
  'eth',
  'gweth',
  'wbtc',
  'gwbtc',
  'usdc',
  'gusdc',
  'usdt',
  'gusdt',
  'vgx',
  'gvgx',
  'els',
  'gels',
];

// Wallet-server and app-server talk about networks a bit differently. Here we convert from the app-server
// way to the wallet-server way, which is what the frontend likes.
function convertCurrencyNetwork(currency: {
  network: 'ethereum' | 'galachain' | 'gyri';
  isTreasureChest: boolean;
}) {
  if (currency.isTreasureChest) {
    if (currency.network === 'ethereum') {
      return 'ETH_TREASURE_CHEST';
    } else if (
      currency.network === 'galachain' ||
      currency.network === 'gyri'
    ) {
      return 'GYRI_TREASURE_CHEST';
    }
  } else {
    if (currency.network === 'ethereum') {
      return 'ETHEREUM';
    } else if (
      currency.network === 'galachain' ||
      currency.network === 'gyri'
    ) {
      return 'GYRI';
    }
  }
}

export const actions: ActionTree<IInventoryState, IRootState> = {
  async getWalletsData({ commit, dispatch, state }) {
    try {
      if (this.app.apolloProvider && !state.isFetchingWallets) {
        commit('updateIsFetchingWallets', true);
        const client = this.app.apolloProvider.defaultClient;

        const {
          data: { wallet },
        } = await client.query({
          query: getWallets,
          fetchPolicy: 'no-cache',
        });

        if (wallet) {
          const withIcons = wallet
            .filter((w: any) => w.platforms?.includes('games') ?? true)
            .map((w: any) => {
              const iconSymbol = w.symbol.replace(
                /\[ETH\]|\[GC\]|\[GalaChainAllowance\]|\[GYRI\]/i,
                '',
              );
              return {
                ...w,
                network: convertCurrencyNetwork(w),
                ethereumContractType: w.contractType,
                icon:
                  w.icon ??
                  `https://static.gala.games/token-icons/${iconSymbol}-icon.png`,
                hideFiat: !showFiatCurrencies.includes(
                  iconSymbol.toLowerCase(),
                ),
              };
            });

          const [galaWallets, otherWallets] = _partition(
            withIcons,
            (walletWithIcon: IWallet) => walletWithIcon.symbol.includes('GALA'),
          );

          commit('updateIsFetchingWallets', false);
          commit('setWalletState', {
            success: true,
            wallets: [...galaWallets, ...otherWallets],
          });
          dispatch('getFiatPrices');
        }
      }
      return;
    } catch (error) {
      this.$sentry.captureException(error);
      commit('setWalletState', { success: false });
      commit('updateIsFetchingWallets', false);
      console.warn(error);
    }
  },

  async getWalletData({ commit, dispatch, state }, coinSymbol: string) {
    if (!state.wallets.length) {
      await dispatch('getWalletsData');
    }
    try {
      if (this.app.apolloProvider) {
        commit('updateIsFetchingWallets', true);
        const client = this.app.apolloProvider.defaultClient;

        const {
          data: { wallet },
        } = await client.query({
          query: getWallets,
          variables: { coinSymbol },
          fetchPolicy: 'no-cache',
        });
        if (wallet && wallet.length) {
          const [walletToUpdate] = wallet;
          const { symbol } = walletToUpdate;

          const iconSymbol = walletToUpdate.symbol.replace(
            /\[ETH\]|\[GC\]|\[GalaChainAllowance\]|\[GYRI\]/i,
            '',
          );
          const walletIcon =
            walletToUpdate.icon ??
            `https://static.gala.games/token-icons/${iconSymbol}-icon.png`;

          walletToUpdate.hideFiat = !showFiatCurrencies.includes(
            iconSymbol.toLowerCase(),
          );

          if (!walletToUpdate.hideFiat) {
            // TODO: now that we are storing token prices in our database, we should include that data in the query to get wallet data instead of relying on the client hitting cryptocompare's api
            dispatch('getFiatPrices', [symbol]);
          }

          commit('updateIsFetchingWallets', false);
          commit('setWallet', {
            ...walletToUpdate,
            icon: walletIcon,
            network: convertCurrencyNetwork(walletToUpdate),
            ethereumContractType: walletToUpdate.contractType,
          });
        }
      }
    } catch (error) {
      this.$sentry.captureException(error);
      commit('updateIsFetchingWallets', false);
      console.warn(error);
    }
  },

  async getFiatPrices({ commit, state }, coinSymbols?: string[]) {
    const { wallets } = state;
    const symbols =
      coinSymbols ||
      wallets
        .filter((wallet: IWallet) => wallet.receiveAddress)
        .map((wallet: IWallet) => wallet.symbol);

    if (symbols.length) {
      try {
        const fiatPrices: any = await cryptocompareService.getPrice(
          symbols,
          'USD',
        );

        commit('updateWalletCoinPrices', fiatPrices);
        return fiatPrices;
      } catch (error) {
        console.warn(error);
      }
    }

    return {};
  },

  async getUserTickets({ commit, rootState }) {
    try {
      if (this.app.apolloProvider && rootState.profile?.user.loggedIn) {
        const client = this.app.apolloProvider.defaultClient;

        const { data } = (await client.query({
          query: getUserTickets,
          variables: {},
          fetchPolicy: 'no-cache' as any,
        })) as {
          data: {
            userTickets: any[];
          };
        };

        commit('updateUserTickets', data.userTickets);
      }
    } catch (error) {
      console.warn(error);
    }
  },

  async getUserItems({ commit, rootState }, collection?: string) {
    // Don't like this solution but didn't find a better way to not re-query
    // the inventory immediately after an item is transferred (where the inventory
    // update is handled via Vuex mutation)
    if (sessionStorage.getItem('skip-next-inventory-query') === 'true') {
      sessionStorage.setItem('skip-next-inventory-query', 'false');
      return;
    }

    if (process.env.mockInventoryName) {
      const mockInventory = mockInventories[process.env.mockInventoryName];
      if (!mockInventories) {
        throw new Error(
          `Could not find ${process.env.mockInventoryName} mock inventory`,
        );
      }

      commit('updateUserItems', { success: true, userItems: mockInventory });
      return 'success';
    }

    try {
      if (this.app.apolloProvider && rootState.profile?.user.loggedIn) {
        const client = this.app.apolloProvider.clients.gateway;

        const results = (await client.query({
          query: getUserItems,
          fetchPolicy: 'network-only',
        })) as {
          data: {
            userItemsV3: {
              items: Array<{
                metadataLookupKey: string;
                [key: string]: any;
              }>;
              metadata: {
                metadataLookupKey: string;
                [key: string]: any;
              };
            };
          };
        };

        const metadataForKey = new Map<string, any>(
          results.data.userItemsV3.metadata.map((m: any) => [
            m.metadataLookupKey,
            m,
          ]),
        );

        const hydratedItems = results.data.userItemsV3.items.map(
          (item: any) => {
            const metadata = metadataForKey.get(item.metadataLookupKey);
            return {
              ...item,
              ...metadata,
            };
          },
        );

        commit('updateUserItems', {
          success: true,
          userItems: hydratedItems,
        });
        return 'success';
      }
    } catch (error) {
      // Swallow
    }

    commit('updateUserItems', { success: false });
    return 'error';
  },

  async getTokenClaimFees({ commit }) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.clients.gateway;
      const {
        data: { tokenClaimFees },
      } = await client.query({
        query: tokenClaimFeesQuery,
        fetchPolicy: 'network-only',
      });

      commit('updateClaimFees', tokenClaimFees);
    }
  },

  async claimTokens(
    { getters },
    {
      walletPassword,
      selectedItems,
      gasCost,
      transactionFeePrice,
      totpToken,
    }: {
      walletPassword?: string;
      selectedItems: any[];
      gasCost: number;
      transactionFeePrice: string;
      totpToken: string;
    },
  ) {
    if (this.app.apolloProvider) {
      const tokens = selectedItems
        .map(({ tokenId, claimType, quantity }) => ({
          tokenId,
          quantity: +quantity,
          claimType,
        }))
        .filter(t => t.quantity > 0);

      if (!tokens.length) {
        throw new Error('Tokens to claim array is empty');
      }

      const client = this.app.apolloProvider.defaultClient;
      const mutation = !walletPassword
        ? claimTokensWithExternalWalletMutation
        : process.env.w3wConnectionEnabled
        ? claimTokensV2Mutation
        : claimTokensMutation;

      const variables = {
        claimFee: gasCost,
        tokens,
        walletPassword,
        transactionFeePrice,
        tokenClaimFeeId: getters.tokenClaimFeeId,
        totpToken,
      };

      const res = await client.mutate({
        mutation,
        variables,
      });
      return res;
    }
  },

  async exchangeGyriTokens(
    { commit },
    {
      exchangeId,
      walletPassword,
      tokens,
      totpToken,
    }: {
      exchangeId: number;
      walletPassword: string;
      totpToken: string;
      tokens: Array<{
        collection: string;
        category: string;
        type: string;
        additionalKey: string;
        instance: string;
      }>;
    },
  ) {
    if (!this.app.apolloProvider) {
      return;
    }

    const client = this.app.apolloProvider.defaultClient;

    const res = await client.mutate({
      mutation: exchangeGyriTokenMutation,
      variables: {
        exchangeId,
        exchangeTokens: tokens.map(
          ({ collection, category, type, additionalKey, instance }) => ({
            tokenInstanceKey: {
              collection,
              category,
              type,
              additionalKey,
              instance,
            },
            quantity: '1',
          }),
        ),
        walletPassword,
        totpToken,
      },
    });

    if (res?.data?.exchangeGyriToken) {
      commit('setGyriExchangeRewards', res.data.exchangeGyriToken.rewards);
      commit('setShowGyriExchangeRewardsModal', true, { root: true });
    }

    return {
      exchangeNetwork: 'GYRI',
      response: res?.data?.exchangeGyriToken,
    };
  },

  async exchangeToken(
    { commit, dispatch },
    {
      quantity,
      walletPassword,
      transactionFeePrice,
      expectedPrice,
      totpToken,
      itemToExchange,
    }: IExchangeTokenOptions,
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (itemToExchange.network === 'ETHEREUM') {
        if (itemToExchange.ethereumTokenStandard !== 'erc1155') {
          throw new Error(
            `Unexpected Ethereum exchange token type: ${itemToExchange.ethereumTokenStandard}`,
          );
        }

        if (!itemToExchange.fungible) {
          throw new Error(
            'Non-fungible ERC1155 tokens are not supported for exchange',
          );
        }

        const res = await client.mutate<
          IBaseGqlResponse<
            'exchangeToken',
            {
              paymentData: {
                smartContractAddress: string;
                smartContractAbi: any[];
                orderPaymentMessage: any;
                signedMessage: string;
              };
            }
          >
        >({
          mutation: exchangeEthTokenMutation,
          variables: {
            network: itemToExchange.network,
            contractAddress: itemToExchange.ethereumContractAddress,
            tokenId: itemToExchange.ethereumBaseId,
            quantity,
            walletPassword,
            transactionFeePrice,
            expectedPrice,
            totpToken,
          },
        });

        if (res?.data?.exchangeToken?.success) {
          commit<ICommitUpdateItemData>({
            type: 'updateUserItem',
            itemUniqueInventoryPath: itemToExchange.uniqueInventoryPath ?? '',
            quantity,
            action: 'exchange',
          });
        }

        return {
          exchangeNetwork: 'ETHEREUM',
          response: res?.data?.exchangeToken,
        };
      }

      if (itemToExchange.network === 'GYRI') {
        return dispatch('exchangeGyriTokens', {
          exchangeId: itemToExchange.gyriExchanges[0].id,
          walletPassword,
          totpToken,
          tokens: [
            {
              collection: itemToExchange.gyriTokenClassKey.collection,
              category: itemToExchange.gyriTokenClassKey.category,
              type: itemToExchange.gyriTokenClassKey.type,
              additionalKey: itemToExchange.gyriTokenClassKey.additionalKey,
              instance: itemToExchange.nonFungibleInstanceId || '0',
            },
          ],
        });
      }

      throw new Error(
        `Unsupported exchange token network: ${itemToExchange.network}`,
      );
    }
  },

  async sendItem(
    { commit },
    {
      item,
      address,
      quantity,
      encryptionPasscode,
      transactionFeePrice,
      totpToken,
    }: ISendItemActionPayload,
  ) {
    if (!item.sendId) {
      throw new Error(
        `Trying to send an item ${item.uniqueInventoryPath} that doesn't have a sendId`,
      );
    }

    const instanceIds = item.fungible
      ? undefined
      : [item.nonFungibleInstanceId];

    const output = {
      to: address,
      amount: quantity.toString(),
      tokenId: item.sendId,
      instanceIds,
    };

    const contractAddress =
      item.network === 'ETHEREUM' ? item.ethereumContractAddress : undefined;

    const network = {
      network: item.network,
      contractAddress,
    };

    const outputs = [output];

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      const res = await client.mutate({
        mutation: sendGameItems,
        variables: {
          outputs,
          walletPassword: encryptionPasscode,
          coinSymbol: 'GALA',
          transactionFeePrice,
          totpToken,
          network,
        },
      });

      if (
        res.data &&
        res.data.sendGameItems &&
        res.data.sendGameItems.success
      ) {
        commit('updateUserItem', {
          itemUniqueInventoryPath: item.uniqueInventoryPath,
          quantity,
          action: 'send',
        });
      }

      return res;
    }
  },

  async bridgeCurrency(
    { commit },
    {
      wallet,
      targetNetwork,
      quantity,
      encryptionPasscode,
      transactionFeePrice,
      totpToken,
      botToken,
      transactionHash,
      destinationChainId,
    }: IBridgeCurrencyActionPayload,
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (targetNetwork === 'GYRI') {
        const contractAddress = wallet.contractAddress;

        const res = await client.mutate({
          mutation: bridgeEthTokenMutation,
          variables: {
            contractAddress,
            tokenId: '',
            quantity: Number(quantity),
            totpToken,
            botToken,
            transactionFeePrice,
            walletPassword: encryptionPasscode,
            transactionHash,
            destinationChainId,
          },
        });

        return {
          success: res?.data?.bridgeEthToken?.success ?? false,
          message: res?.data?.bridgeEthToken?.message,
        };
      } else if (targetNetwork === 'ETHEREUM') {
        const [
          collection,
          category,
          type,
          additionalKey,
        ] = wallet.tokenId.includes('|')
          ? wallet.tokenId.split('|')
          : [wallet.tokenId.toUpperCase(), 'Unit', 'none', 'none'];

        const tokenInstance = {
          collection,
          category,
          type,
          additionalKey,
          instance: '0',
        };

        const res = await client.mutate({
          mutation: bridgeGyriTokenMutation,
          variables: {
            tokenInstance,
            quantity: Number(quantity),
            totpToken,
            botToken,
            walletPassword: encryptionPasscode,
            bridgeFeePrice: transactionFeePrice,
          },
        });

        return {
          success: res?.data?.bridgeGyriToken?.success ?? false,
          message: res?.data?.bridgeGyriToken?.message,
        };
      }
    }
  },

  async swapCurrency(
    { commit },
    {
      wallet,
      tokenId,
      targetNetwork,
      quantity,
      encryptionPasscode,
      transactionFeePrice,
      totpToken,
      botToken,
      transactionHash,
    }: ISwapCurrencyActionPayload,
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      const contractAddress = wallet.contractAddress;

      const res = await client.mutate({
        mutation: swapEthTokenMutation,
        variables: {
          contractAddress,
          tokenId,
          quantity: Number(quantity),
          totpToken,
          botToken,
          transactionFeePrice,
          walletPassword: encryptionPasscode,
          transactionHash,
        },
      });

      return {
        success: res?.data?.swapEthToken?.success ?? false,
        message: res?.data?.swapEthToken?.message,
      };
    }
  },

  async batchBridgeItems(
    { commit },
    {
      selectedTokens,
      walletPassword,
      transactionFeePrice,
      totpToken,
      botToken,
      transactionHash,
      destinationChainId,
    }: IBatchBridgeItemsActionPayload,
  ) {
    if (!selectedTokens[0].sendId) {
      throw new Error(
        `Trying to bridge an item ${selectedTokens[0].uniqueInventoryPath} that doesn't have a sendId`,
      );
    }
    const tokens: Array<{ tokenId: string; quantity: number }> = [];
    const selectedItems: any[] = [];

    selectedTokens.forEach(item => {
      if (item.selected && item.selectedQuantity && item.selectedQuantity > 0) {
        tokens.push({
          tokenId: item.sendId as string,
          quantity: item.selectedQuantity as number,
        });
        selectedItems.push(item);
      }
    });

    const contractAddress =
      selectedTokens[0].network === 'ETHEREUM'
        ? selectedTokens[0].ethereumContractAddress
        : undefined;

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (selectedTokens[0].network === 'ETHEREUM') {
        const variables = transactionHash
          ? {
              contractAddress,
              tokens,
              transactionHash,
              destinationChainId,
              totpToken: '',
              botToken,
            }
          : {
              contractAddress,
              tokens,
              totpToken,
              botToken,
              walletPassword,
              transactionFeePrice,
              destinationChainId,
            };
        const res = await client.mutate({
          mutation: batchBridgeEthTokenMutation,
          variables,
        });

        if (
          res.data &&
          res.data.batchBridgeEthToken &&
          res.data.batchBridgeEthToken.success
        ) {
          selectedItems.forEach(item => {
            commit('updateUserItem', {
              itemUniqueInventoryPath: item.uniqueInventoryPath,
              quantity: item.selectedQuantity,
              action: 'send',
            });
          });
        }

        return res;
      }
    }
  },

  async bridgeItem(
    { commit },
    {
      item,
      quantity,
      encryptionPasscode,
      transactionFeePrice,
      totpToken,
      botToken,
      transactionHash,
      destinationChainId,
    }: IBridgeItemActionPayload,
  ) {
    if (!item.sendId) {
      throw new Error(
        `Trying to bridge an item ${item.uniqueInventoryPath} that doesn't have a sendId`,
      );
    }

    const contractAddress =
      item.network === 'ETHEREUM' ? item.ethereumContractAddress : undefined;

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (item.network === 'ETHEREUM') {
        const res = await client.mutate({
          mutation: bridgeEthTokenMutation,
          variables: {
            contractAddress,
            tokenId: item.sendId,
            quantity,
            totpToken,
            botToken,
            transactionFeePrice,
            walletPassword: encryptionPasscode,
            transactionHash,
            destinationChainId,
          },
        });

        if (
          res.data &&
          res.data.bridgeEthToken &&
          res.data.bridgeEthToken.success
        ) {
          commit('updateUserItem', {
            itemUniqueInventoryPath: item.uniqueInventoryPath,
            quantity,
            action: 'send',
          });
        }

        return res;
      } else if (item.network === 'GYRI') {
        const {
          collection,
          category,
          type,
          additionalKey,
        } = item.gyriTokenClassKey;
        const res = await client.mutate({
          mutation: bridgeGyriTokenMutation,
          variables: {
            tokenInstance: {
              collection,
              category,
              type,
              additionalKey,
              instance: item.fungible ? '0' : item.nonFungibleInstanceId,
            },
            quantity,
            totpToken,
            botToken,
            walletPassword: encryptionPasscode,
            bridgeFeePrice: transactionFeePrice,
          },
        });

        if (
          res.data &&
          res.data.bridgeEthToken &&
          res.data.bridgeEthToken.success
        ) {
          commit('updateUserItem', {
            itemUniqueInventoryPath: item.uniqueInventoryPath,
            quantity,
            action: 'send',
          });
        }

        return res;
      }
    }
  },

  async fulfillAllowance(
    { commit },
    {
      item,
      quantity,
      totpToken,
      walletPassword,
      transactionFeePrice,
    }: IFulfillAllowanceActionPayload,
  ) {
    if (
      !item.allowanceType ||
      !item.gyriTokenClassKey ||
      item.network !== 'GALACHAIN_ALLOWANCE'
    ) {
      throw new Error('Invalid item');
    }

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      const {
        collection,
        category,
        type,
        additionalKey,
      } = item.gyriTokenClassKey;

      const res = await client.mutate({
        mutation: fulfillGalaChainItemAllowanceMutation,
        variables: {
          tokenClassKey: { collection, category, type, additionalKey },
          type: item.allowanceType,
          allowanceTransferFrom: item.allowanceTransferFrom,
          quantity,
          totpToken,
          walletPassword,
          transactionFeePrice,
        },
      });

      if (
        res.data &&
        res.data.fulfillGalaChainItemAllowance &&
        res.data.fulfillGalaChainItemAllowance.success
      ) {
        commit('updateUserItem', {
          itemUniqueInventoryPath: item.uniqueInventoryPath,
          quantity,
          action: 'send',
        });
      }

      return res;
    }
  },

  async hasTransferLock({ commit }, item: UserItem) {
    if (item.network === 'GYRI') {
      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;
        const {
          collection,
          category,
          type,
          additionalKey,
        } = item.gyriTokenClassKey;
        const { data } = await client.query({
          query: hasTransferLock,
          variables: {
            tokenInstance: {
              instance: item.fungible ? '0' : item.nonFungibleInstanceId,
              collection,
              category,
              type,
              additionalKey,
            },
          },
        });
        return data.hasTransferLock;
      }
    } else {
      return { locked: false };
    }
  },
};
