import { ActionTree } from 'vuex';
import { IProfileState } from './types';
import { IRootState } from '../types';
import awsService from '../../services/aws.service';
import updateUser from '../../mutations/updateUser.gql';
import getIpLocation from '../../queries/getIpLocation.gql';
import getUser from '../../queries/getUser.gql';
import gameJWTQuery from '../../queries/gameJWT.gql';
import verifyEmailAddressMutation from '~/mutations/verifyEmailAddress.gql';
import createBlankUserMutation from '~/mutations/createBlankUser.gql';
import galaHonor from '~/queries/galaHonor.gql';
import { INetworkNotifications } from '~/types/notification';
import { GraphQLErrorCodes, hasErrorCode } from '~/utils/errorCodes';
import { resetCache } from '~/utils';

export const actions: ActionTree<IProfileState, IRootState> = {
  clearUser({ commit }) {
    commit('clearUser');
  },

  async getIpLocation({ commit, state }) {
    if (state.ipLocation?.country) {
      return state.ipLocation;
    }

    if (this.app.apolloProvider) {
      try {
        const client = this.app.apolloProvider.defaultClient;

        const rawData = await client.query({
          query: getIpLocation,
          fetchPolicy: 'cache-first',
        });

        if (rawData?.data?.ipLocation) {
          commit('updateIpLocation', rawData.data.ipLocation);
          return rawData.data.ipLocation;
        } else {
          throw new Error('No region data returned from IP query');
        }
      } catch (error) {
        console.error(error);
        this.$sentry.captureException(error);
      }
    }
  },

  async getUserData({ commit, dispatch }) {
    if (this.app.apolloProvider) {
      try {
        commit('updateIsFetching', true);
        const client = this.app.apolloProvider.defaultClient;

        let rawData = await client.query({
          query: getUser,
          fetchPolicy: 'cache-first',
        });

        if (!rawData?.data?.profile) {
          const errorMessage =
            'getUser query returned no data. Fetching without cache.';
          console.warn(errorMessage);
          this.$sentry.captureMessage(errorMessage);

          rawData = await client.query({
            query: getUser,
            fetchPolicy: 'network-only',
          });
        }

        const user = {
          ...rawData.data.profile,
          gameToken: rawData.data.gameJWT.token,
        };

        const externalWalletData = {
          externalWallets: user.externalWallets,
          connectedExternalWallet: user.connectedExternalWallet,
        };

        commit('updateIsFetching', false);
        commit('updateUser', user);
        commit('web3Wallet/filterProviderOptionsByUserId', user.id, {
          root: true,
        });
        commit('web3Wallet/updateExternalWallets', externalWalletData, {
          root: true,
        });
        this.$sentry.setUser({ id: user.id });
      } catch (error) {
        console.error(error);
        this.$sentry.captureException(error);
        commit('updateIsFetching', false);
      }
    }
  },

  async refreshUser({ commit, dispatch }) {
    if (this.app.apolloProvider) {
      try {
        commit('updateIsFetching', true);
        const client = this.app.apolloProvider.defaultClient;

        const rawData = await client.query({
          query: getUser,
          fetchPolicy: 'network-only',
        });

        const user = {
          ...rawData.data.profile,
          gameToken: rawData.data.gameJWT.token,
        };

        const externalWalletData = {
          externalWallets: user.externalWallets,
          connectedExternalWallet: user.connectedExternalWallet,
        };

        commit('updateIsFetching', false);
        commit('updateUser', user);
        commit('web3Wallet/filterProviderOptionsByUserId', user.id, {
          root: true,
        });
        commit('web3Wallet/updateExternalWallets', externalWalletData, {
          root: true,
        });
        dispatch('web3Wallet/reestablishW3wConnection', null, { root: true });
        this.$sentry.setUser({ id: user.id });
      } catch (error) {
        this.$sentry.captureException(error);
        commit('updateIsFetching', false);
      }
    }
  },

  async createBlankUser({ commit, rootState }, referralContext: any) {
    const formattedReferralContext = Object.entries(referralContext).reduce(
      (formatted, [key, value]) => {
        if (key.includes('utm') && Array.isArray(value)) {
          return {
            ...formatted,
            [key]: JSON.stringify(value),
          };
        }

        return {
          ...formatted,
          [key]: value,
        };
      },
      {},
    );

    try {
      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;
        const { data } = await client.mutate({
          mutation: createBlankUserMutation,
          variables: {
            referralContext: formattedReferralContext,
            clientRoute: this.app.router?.currentRoute.path,
            userAgentInfo: rootState.userAgentInfo,
          },
        });

        if (data.createBlankUser && data.createBlankUser.token) {
          commit('updateGameJWT', {
            token: data.createBlankUser.token,
            timeSet: new Date().getTime(),
          });
        }
      }
    } catch (error) {
      this.$sentry.captureException(error);
    }
  },

  async updateUser(
    { commit },
    args: {
      firstname?: string;
      lastName?: string;
      displayName?: string;
      profilePhotoUrl?: string;
      profilePhotoFilename?: string;
      phone?: string;
      email?: string;
      password?: string;
      ignoreSnackbar?: boolean;
      networkNotifications?: INetworkNotifications;
      totpToken: string;
      rethrowErrorCodes?: GraphQLErrorCodes[];
      currentPassword?: string;
    },
  ) {
    const {
      ignoreSnackbar,
      totpToken,
      rethrowErrorCodes = [],
      ...userInfo
    } = args;

    try {
      const mutationUserInfo = { ...userInfo, profilePhotoUrl: undefined };

      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;

        const rawData = await client.mutate({
          mutation: updateUser,
          variables: {
            userInfo: mutationUserInfo,
            totpToken,
          },
          refetchQueries: [
            {
              query: getUser,
            },
          ],
        });

        if (rawData.data.updateUser.success) {
          if (userInfo.hasOwnProperty('password')) {
            delete userInfo.password;
          }

          // UA tracking
          this.$ua.trackUpdateUserCompleteEvent(userInfo);

          commit('updateUser', userInfo);

          if (!ignoreSnackbar) {
            commit(
              'updateSnackbarSuccessText',
              this.app.i18n.t(
                'store.profile.actions.updateSuccessful',
              ) as string,
              {
                root: true,
              },
            );

            commit('toggleSuccessSnackbar', null, { root: true });
          }

          return { success: true };
        } else {
          commit(
            'updateSnackbarErrorText',
            this.app.i18n.t('store.profile.actions.updateFailed') as string,
            { root: true },
          );
          commit('toggleErrorSnackbar', null, { root: true });

          // UA tracking
          this.$ua.trackUpdateUserErrorEvent({
            message: this.app.i18n.t('store.profile.actions.updateFailed'),
          });

          return { success: false };
        }
      }
    } catch (error) {
      // UA tracking
      this.$ua.trackUpdateUserErrorEvent(error);

      if (rethrowErrorCodes.some(code => hasErrorCode(error, code))) {
        throw error;
      }

      this.$sentry.captureException(error);
      commit(
        'updateSnackbarErrorText',
        this.app.i18n.t('store.profile.actions.updateFailed') as string,
        { root: true },
      );
      commit('toggleErrorSnackbar', null, { root: true });
      return { success: false };
    }
  },

  async uploadUserPhoto({ commit }, file: File) {
    try {
      if (!file.type.startsWith('image/') || file.type.includes('svg')) {
        throw new Error('file must be an image type');
      }
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = evt => {
        const contents = String(evt?.target?.result);
        if (contents?.includes('svg')) {
          throw new Error('file must be an image type');
        }
      };
      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;

        const {
          data: {
            getS3PresignedPost: { signedRequest, url, filename },
          },
        } = await awsService.getS3PresignedPost(file.name, file.type, client);

        await awsService.postProfilePictureToS3(signedRequest, file);

        // UA tracking
        this.$ua.trackUploadUserPhotoCompleteEvent({
          url,
          filename,
        });

        return { url, filename };
      }
    } catch (error) {
      const err = error as Error;
      // UA tracking
      this.$ua.trackUploadUserPhotoErrorEvent(err);

      let errorMessage = '';
      if (err.message.includes('file must be an image type')) {
        errorMessage = this.app.i18n.t(
          'store.profile.actions.fileMustBeImage',
        ) as string;
      } else if (
        err.message.includes('proposed upload exceeds the maximum allowed size')
      ) {
        errorMessage = this.app.i18n.t(
          'store.profile.actions.photoExceedsMax',
        ) as string;
      } else {
        this.$sentry.captureException(err);
        errorMessage = this.app.i18n.t(
          'store.profile.actions.photoUploadFailed',
        ) as string;
      }

      commit('updateSnackbarErrorText', errorMessage, {
        root: true,
      });
      commit('toggleErrorSnackbar', null, { root: true });
    }
  },

  async verifyEmail(
    { commit },
    { token }: { token: string; newUser: boolean },
  ) {
    if (this.app.apolloProvider) {
      const apollo = this.app.apolloProvider.defaultClient;

      const { data } = await apollo.mutate({
        mutation: verifyEmailAddressMutation,
        variables: {
          token,
        },
      });

      if (data && data.verifyEmailAddress && data.verifyEmailAddress.success) {
        commit('updateUser', { emailVerified: true });
      }
      return data;
    }
  },
  async getGameJWT({ commit, state }) {
    if (this.app.apolloProvider) {
      try {
        const client = this.app.apolloProvider.defaultClient;
        const { gameTokenMaxAge, gameTokenTimeSet } = state;
        const nowInMs = new Date().getTime();
        const isTokenExpired =
          nowInMs - gameTokenTimeSet > gameTokenMaxAge || !gameTokenTimeSet
            ? true
            : false;
        const fetchPolicy = isTokenExpired ? 'network-only' : 'cache-first';
        const { data } = await client.query({
          query: gameJWTQuery,
          fetchPolicy,
        });
        if (data.gameJWT && data.gameJWT.token) {
          commit('updateGameJWT', {
            token: data.gameJWT.token,
            timeSet: isTokenExpired ? nowInMs : gameTokenTimeSet,
          });
        }
      } catch (error) {
        this.$sentry.captureException(error);
      }
    }
  },

  async userLogout() {
    // The following line resets the entire apollo store. this was necessary
    // in order to remove artifacts from the previous user in the case of
    // multiple logins on a single device.
    if (this.app.apolloProvider) {
      const defaultClientCache = this.app.apolloProvider.defaultClient.cache;
      const gatewayClientCache = this.app.apolloProvider.clients.gateway.cache;

      await Promise.all([
        resetCache(defaultClientCache),
        resetCache(gatewayClientCache, false),
      ]);
    }

    // UA tracking
    this.app.$ua.trackLogoutEvent();

    this.$auth.logout();
  },

  async fetchGalaHonor({ commit }) {
    if (this.app.apolloProvider) {
      try {
        const client = this.app.apolloProvider.defaultClient;
        const { data } = await client.query({
          query: galaHonor,
          fetchPolicy: 'cache-first',
        });

        if (data.galaHonor) {
          commit('updateGalaHonor', data.galaHonor);
          return data.galaHonor;
        } else {
          return 0;
        }
      } catch (error) {
        this.$sentry.captureException(error);
        return 0;
      }
    }
  },
};
