var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"top-toolbar"},[(!_vm.$vuetify.breakpoint.mdAndUp && _vm.mobileSearch)?_c('nav',{staticClass:"d-flex align-center py-4 px-6",attrs:{"tag":"nav"}},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"white","size":"30"},on:{"click":function($event){_vm.mobileSearch = false}}},[_vm._v("\n        mdi-arrow-left\n      ")]),_vm._v(" "),_c('SearchBar',{on:{"search":_vm.search}})],1):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp || !_vm.mobileSearch)?_c('nav',{staticClass:"d-flex align-center py-4 px-6",attrs:{"tag":"nav"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',{attrs:{"color":"white","size":"30"},on:{"click":function($event){_vm.mobileDrawerControl = !_vm.mobileDrawerControl}}},[_vm._v("\n        mdi-menu\n      ")]):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-icon',{staticClass:"pl-4",attrs:{"color":"white","size":"30"},on:{"click":function($event){_vm.mobileSearch = true}}},[_vm._v("\n        mdi-magnify\n      ")]):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"d-inline-flex",class:{
          'mx-auto': _vm.$vuetify.breakpoint.smAndDown,
          'pr-8': _vm.$vuetify.breakpoint.smAndUp,
        },attrs:{"to":"/"}},[_c('GalaLogo',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 30 : 40}})],1),_vm._v(" "),(_vm.$vuetify.breakpoint.lgAndUp)?_c('GalaTagLine',{staticClass:"mr-8"}):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('SearchBar',{staticClass:"mr-auto",staticStyle:{"max-width":"700px"},on:{"search":_vm.search}}):_vm._e(),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-items',{staticClass:"pl-5"},[_vm._l((_vm.tabs),function(tab,i){return _c('v-btn',{key:i,class:{
            'top-nav-btn text-capitalize px-6 py-2 textColor--text': true,
            'top-nav-btn-small-screen': _vm.$vuetify.breakpoint.width < 1250,
            'd-none': tab.mobile,
          },attrs:{"data-cy":("nav-" + (tab.title.toLowerCase())),"text":"","exact":"","to":tab.target ? null : tab.to,"href":tab.target ? tab.to : null,"target":tab.target,"ripple":false}},[_vm._v("\n          "+_vm._s(tab.name)+"\n        ")])}),_vm._v(" "),_c('div',{staticClass:"nav-circle-item ml-8 mr-4"},[_c('DiscordIcon',{attrs:{"width":"20px"},on:{"click":function($event){return _vm.onTrackDiscordClick(_vm.discordLink)}}})],1)],2):_vm._e(),_vm._v(" "),_c('ReferAFriend'),_vm._v(" "),_c('PromoPackDialog'),_vm._v(" "),(_vm.currentUser.loggedIn)?_c('div',{staticClass:"profile-controls"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('nuxt-link',{staticClass:"notifications mr-4 nav-circle-item",attrs:{"to":"/notifications"}},[_c('NotificationBell',{class:(_vm.accountControlsColor + "--text")})],1):_vm._e(),_vm._v(" "),_c('ProfileProgressIcon',{staticStyle:{"cursor":"pointer"},attrs:{"id":"menu-icon","aria-label":"menu options","profile-photo-url":_vm.currentUser.profilePhotoUrl},nativeOn:{"click":function($event){_vm.drawerControl = !_vm.drawerControl}}})],1):_c('LoginAndRegister')],1):_vm._e(),_vm._v(" "),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.showCongratsModal),callback:function ($$v) {_vm.showCongratsModal=$$v},expression:"showCongratsModal"}},[(_vm.showCongratsModal)?_c('CongratsModal',{attrs:{"closeModal":function () { return (_vm.showCongratsModal = false); }}}):_vm._e()],1)],1),_vm._v(" "),_c('ProfileNavigationDrawer',{attrs:{"drawerControl":_vm.drawerControl},on:{"close":function($event){_vm.drawerControl = false}}}),_vm._v(" "),_c('MobileNavigationDrawer',{attrs:{"tabs":_vm.tabs,"drawerControl":_vm.mobileDrawerControl},on:{"close":function($event){_vm.mobileDrawerControl = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }