import { Component, Vue } from 'vue-property-decorator';
import { Mutation, Action, State } from 'vuex-class';
import { IClaimFee, IWallet, IClaimDetail } from '~/store/inventory/types';
import galachainTransactionFeeEstimateQuery from '~/queries/wallets/galachainTransactionFeeEstimate.gql';
import transactionFeeEstimateQuery from '~/queries/wallets/transactionFeeEstimate.gql';
import bridgeFeeEstimateQuery from '~/queries/wallets/bridgeFeeEstimate.gql';
import bridgeOutGyriFeeEstimateQuery from '~/queries/wallets/bridgeOutGyriFeeEstimate.gql';
import {
  GalaChainMethods,
  ITokenInsanceKeyWithQuantity,
} from '~/types/gala-chain';

@Component
export default class TransactionFees extends Vue {
  @State('claimFees', { namespace: 'inventory' })
  private claimFees!: IClaimFee[];
  @State('claimNetwork', { namespace: 'inventory' })
  claimNetwork!: { network: string; currency: string };
  @State('wallets', { namespace: 'inventory' })
  readonly wallets!: IWallet[];
  @State(profile => profile.user.walletExists, { namespace: 'profile' })
  readonly walletExists!: boolean;

  @Mutation toggleSuccessSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarSuccessText!: (args: any) => void;
  @Mutation toggleErrorSnackbar!: (payload?: boolean) => void;
  @Mutation updateSnackbarErrorText!: (args: any) => void;

  @Action('getTokenClaimFees', { namespace: 'inventory' })
  private getTokenClaimFees!: () => void;

  isFetching = false;
  speedUpDialog = false;
  requirePasscode = false;
  insufficientFundsDialog = false;

  transactionFeeMin = 0;
  transactionFeeMax = 0;
  transactionFeeDefault = 0;
  selectedFee = 0;
  transactionFeeUnits = 0;
  transactionFeeDecimals = 0;
  isFetchingTransferFee = false;
  isFetchingBridgeFees = false;
  isFetchingSwapFees = false;
  bridgeFees: any = undefined;
  swapFees: any = undefined;
  gyriBridgeFee: any = undefined;
  galaChainBridgeFeeUsd = 0;
  galaChainSurgeLevel = 0;

  // Comes from extending component
  transactionFeeCurrency = 'ETH';
  transferNetwork = {
    network: '',
    contractType: '',
    contractAddress: '',
    nonFungible: false,
  };
  quantifiedClaimDetails: IClaimDetail[] | null = null;

  get transactionFee() {
    const total = this.selectedFee / 10 ** this.transactionFeeDecimals;

    return total * this.transactionFeeUnits;
  }

  get exportFee() {
    return 0;
  }

  get filteredClaimDetails() {
    return this.quantifiedClaimDetails
      ? this.quantifiedClaimDetails.filter(token => token.quantity > 0)
      : [];
  }

  get canSpeedUp() {
    return this.transactionFeeWallet?.network === 'ETHEREUM';
  }

  get tokenClaimFee() {
    if (!this.filteredClaimDetails.every(token => token.claimType)) {
      return 0;
    }

    if (!this.filteredClaimDetails.length || !this.claimFees.length) {
      return 0;
    }

    const claimFee = this.claimFees.find(
      fee => fee.network === this.claimNetwork.network,
    ) as IClaimFee;

    const groupedTokens = this.filteredClaimDetails.reduce(
      (grouped: any[], token: any) => {
        const quantityToAdd = token.nonFungible ? token.quantity : 1;
        const existingIndex = grouped.findIndex(
          ({ contractAddress }) =>
            contractAddress === this.transferNetwork.contractAddress,
        );

        if (existingIndex >= 0) {
          const existing = grouped[existingIndex];

          const newToken = {
            ...existing,
            quantity: existing.quantity + quantityToAdd,
          };

          return grouped.map((t, i) => {
            if (i === existingIndex) {
              return newToken;
            }

            return t;
          });
        }

        return [...grouped, { ...token, quantity: quantityToAdd }];
      },
      [],
    );

    const total = groupedTokens.reduce((aggr, token) => {
      const fee = claimFee.contractTypes.find(
        type => type.contractType === this.transferNetwork.contractType,
      );

      if (!fee) {
        // throw new Error('Invalid token claim fee');
        return 0;
      }

      const feeDetails = token.nonFungible ? fee.nonFungible : fee.fungible;
      const requiredTransactions = Math.ceil(
        token.quantity / feeDetails.maxBatchSize,
      );
      const tokenFee =
        requiredTransactions * feeDetails.minBatchFee +
        token.quantity * feeDetails.perTokenFee;

      return aggr + tokenFee;
    }, 0);

    const rounded = Math.ceil(total * 10 ** 6) / 10 ** 6;

    return rounded;
  }

  get ethWallet() {
    return this.wallets.find(wallet => wallet.symbol === 'ETH');
  }

  get gasCost() {
    return this.tokenClaimFee + this.transactionFee;
  }

  get transactionFeeWallet() {
    return this.wallets
      ? this.wallets.find(
          wallet => wallet.symbol === this.transactionFeeCurrency,
        )
      : undefined;
  }

  get gasCostUsd() {
    if (this.transactionFeeWallet && this.transactionFee) {
      return this.transactionFeeWallet.coinPrice * this.gasCost;
    }

    return 0;
  }

  get isFetchingEstimatedFee() {
    return this.isFetchingTransferFee || this.isFetchingBridgeFees || false;
  }

  get isGalaChainGasFeeHigh() {
    return this.galaChainSurgeLevel > 0;
  }

  created() {
    this.getTokenClaimFees();
    this.getBridgeFeeEstimates();
  }

  async getSwapFeeEstimates() {
    this.getBridgeFeeEstimates();
  }

  async getBridgeFeeEstimates() {
    this.isFetchingBridgeFees = true;
    this.isFetchingSwapFees = true;

    const { data } = await this.$apolloProvider.clients.gateway.query<{
      transactionFeeEstimate: {
        gasUnitsEstimate: number;
        gasPriceEstimate: {
          high: string;
          suggested: string;
          low: string;
        };
      };
    }>({
      query: bridgeFeeEstimateQuery,
      fetchPolicy: 'network-only',
    });

    this.isFetchingBridgeFees = false;
    this.isFetchingSwapFees = false;
    this.bridgeFees = data;
    this.swapFees = data;
  }

  async getBridgeOutGyriFeeEstimate(tokenClassKey: {
    collection: string;
    category: string;
    type: string;
    additionalKey: string;
  }) {
    this.isFetchingBridgeFees = true;

    const { data } = await this.$apolloProvider.defaultClient.query<{
      bridgeOutGyriFeeEstimate: {
        gasFee: number;
        gasFeeUsd: number;
        gasToken: string;
        surge: number;
      };
    }>({
      query: bridgeOutGyriFeeEstimateQuery,
      variables: {
        tokenClassKey,
      },
      fetchPolicy: 'network-only',
    });

    this.isFetchingBridgeFees = false;
    this.gyriBridgeFee = data.bridgeOutGyriFeeEstimate.gasFee;
    this.galaChainBridgeFeeUsd = data.bridgeOutGyriFeeEstimate?.gasFeeUsd
      ? data.bridgeOutGyriFeeEstimate.gasFeeUsd
      : 0;
    this.galaChainSurgeLevel = data.bridgeOutGyriFeeEstimate?.surge
      ? data.bridgeOutGyriFeeEstimate.surge
      : 0;
  }

  async getTransactionFeeEstimate(symbol = 'ETH') {
    this.isFetchingTransferFee = true;

    const { data } = await this.$apolloProvider.clients.gateway.query<{
      transactionFeeEstimate: {
        gasUnitsEstimate: number;
        gasPriceEstimate: {
          high: string;
          suggested: string;
          low: string;
        };
      };
    }>({
      query: transactionFeeEstimateQuery,
      variables: { symbol },
      fetchPolicy: 'network-only',
    });

    this.transactionFeeMin = +data.transactionFeeEstimate.gasPriceEstimate.low;
    this.transactionFeeMax = +data.transactionFeeEstimate.gasPriceEstimate.high;
    this.transactionFeeDefault = +data.transactionFeeEstimate.gasPriceEstimate
      .suggested;
    this.selectedFee = this.transactionFeeDefault;
    this.transactionFeeUnits = data.transactionFeeEstimate.gasUnitsEstimate;
    this.transactionFeeDecimals = 18;
    this.isFetchingTransferFee = false;
  }

  async getGalachainTransactionFeeEstimate(
    transactionData: Array<{
      method: GalaChainMethods;
      tokenInstances: ITokenInsanceKeyWithQuantity[];
      target?: string;
    }>,
  ) {
    this.isFetchingTransferFee = true;
    const { data } = await this.$apolloProvider.clients.gateway.query<{
      galachainTransactionFeeEstimate: {
        total: string;
        tokenSymbol: string;
      };
    }>({
      query: galachainTransactionFeeEstimateQuery,
      variables: { transactionData },
      fetchPolicy: 'network-only',
    });

    this.transactionFeeCurrency =
      data.galachainTransactionFeeEstimate.tokenSymbol;
    this.transactionFeeMin = +data.galachainTransactionFeeEstimate.total;
    this.transactionFeeMax = +data.galachainTransactionFeeEstimate.total;
    this.transactionFeeDefault = +data.galachainTransactionFeeEstimate.total;
    this.selectedFee = this.transactionFeeDefault;

    this.transactionFeeDecimals = 0;
    this.transactionFeeUnits = 1;

    this.isFetchingTransferFee = false;
  }
}
