import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import {
  IRootState,
  ICurrentPage,
  IPreviousPage,
  IUserAgentInfo,
} from './types';
import profile from './profile/index';
import inventory from './inventory/index';
import games from './games/index';
import share from './share';
import notifications from './notifications';
import cmsContent from './cms_content';
import web3Wallet from './web3_wallet';
import customReveal from './customReveal';
import { setRedirectAfterLogin } from '~/utils/redirectAfterLogin';
import { getDefaultNumericFormatCode } from '~/utils/locales';
import { ISanityPromoPack } from './cms_content/types';

Vue.use(Vuex);

const store: StoreOptions<IRootState> = {
  state: {
    version: '1.0.0',
    showSuccessSnackbar: false,
    showErrorSnackbar: false,
    createWalletPrompt: {
      show: false,
      walletType: 'eth',
    },
    showDiscordDialog: false,
    showDiscordConnectingDialog: false,
    showGyriExchangeRewardsModal: false,
    showReferralDialog: false,
    showPromoPackDialog: false,
    activePromoPack: undefined,
    snackbarErrorText: 'Something went wrong.',
    snackbarSuccessText: 'Success.',
    currentPage: { title: '', showBoxCoinBalance: false },
    previousPage: { path: '/', sameAsCurrent: true },
    showSettingsPage: '',
    discordLink: 'https://gogames.gala.com/b_discord',
    userAgentInfo: { language: 'en-US', displayMode: 'browser' },
    apiErrorCodes: [],
    showResetDialog: false,
    legalDocumentSuppressors: [],
    browserPersistentSettings: {},
  },
  modules: {
    profile,
    inventory,
    games,
    share,
    notifications,
    cmsContent,
    web3Wallet,
    customReveal,
  },
  mutations: {
    setBrowserPersistentSetting(
      rootState: IRootState,
      payload: { key: string; value: any },
    ) {
      rootState.browserPersistentSettings = {
        ...rootState.browserPersistentSettings,
        [payload.key]: payload.value,
      };
    },
    suppressLegalDocuments(rootState: IRootState, payload: string) {
      rootState.legalDocumentSuppressors = [
        ...rootState.legalDocumentSuppressors,
        payload,
      ];
    },
    unsuppressLegalDocuments(rootState: IRootState, payload: string) {
      rootState.legalDocumentSuppressors = rootState.legalDocumentSuppressors.filter(
        s => s !== payload,
      );
    },
    toggleSuccessSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showSuccessSnackbar =
        payload === undefined || payload === null
          ? !rootState.showSuccessSnackbar
          : payload;
    },
    toggleErrorSnackbar(rootState: IRootState, payload?: boolean) {
      rootState.showErrorSnackbar =
        payload === undefined || payload === null
          ? !rootState.showErrorSnackbar
          : payload;
    },
    toggleCreateWalletPrompt(
      rootState: IRootState,
      payload?: {
        show?: boolean;
        walletType?: string;
      },
    ) {
      rootState.createWalletPrompt.show =
        payload?.show ?? !rootState.createWalletPrompt.show;
      rootState.createWalletPrompt.walletType = payload?.walletType || 'eth';
    },
    toggleDiscordDialog(rootState: IRootState, payload?: boolean) {
      rootState.showDiscordDialog =
        payload === undefined || payload === null
          ? !rootState.showDiscordDialog
          : payload;
    },
    toggleDiscordConnectingDialog(rootState: IRootState, payload?: boolean) {
      rootState.showDiscordConnectingDialog =
        payload === undefined || payload === null
          ? !rootState.showDiscordConnectingDialog
          : payload;
    },
    toggleReferralDialog(rootState: IRootState, payload?: boolean) {
      rootState.showReferralDialog =
        payload === undefined || payload === null
          ? !rootState.showReferralDialog
          : payload;
    },
    togglePromoPackDialog(
      rootState: IRootState,
      payload: { active: boolean; promoPack?: ISanityPromoPack },
    ) {
      rootState.activePromoPack =
        payload.promoPack ?? rootState.activePromoPack;
      rootState.showPromoPackDialog = payload?.active;
    },
    updateSnackbarSuccessText(rootState: IRootState, payload: string) {
      rootState.snackbarSuccessText = payload;
    },
    updateSnackbarErrorText(rootState: IRootState, payload: string) {
      rootState.snackbarErrorText = payload;
    },
    updateCurrentPage(rootState: IRootState, payload: ICurrentPage) {
      rootState.currentPage = payload;
    },
    updatePreviousPage(rootState: IRootState, payload: IPreviousPage) {
      rootState.previousPage = payload;
    },
    updateShowSettingsPage(rootState: IRootState, payload: string) {
      rootState.showSettingsPage = payload;
    },
    updateUserAgentInfo(rootState: IRootState, payload: IUserAgentInfo) {
      rootState.userAgentInfo = payload;
    },
    addApiErrorIds(rootState: IRootState, payload: string[]) {
      const timestamp = new Date().toISOString();
      const payloadWithTimestamp = payload.map(id => ({ id, timestamp }));
      rootState.apiErrorCodes = [
        ...payloadWithTimestamp,
        ...rootState.apiErrorCodes,
      ].slice(0, 10);

      localStorage.setItem(
        'apiErrorIds',
        JSON.stringify(rootState.apiErrorCodes),
      );
    },
    setShowGyriExchangeRewardsModal(rootState: IRootState, payload: boolean) {
      rootState.showGyriExchangeRewardsModal = payload;
    },
  },
  actions: {
    promptToLogin({}, { redirectAfterLoginPath }) {
      if (redirectAfterLoginPath) {
        setRedirectAfterLogin(redirectAfterLoginPath);
      }

      return this.$auth.loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
      });
    },
    promptToRegister({}, { redirectAfterLoginPath, connection } = {}) {
      if (redirectAfterLoginPath) {
        setRedirectAfterLogin(redirectAfterLoginPath);
      }

      return this.$auth.loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
          screen_hint: 'signup',
          connection,
        },
      });
    },
  },
  getters: {
    numericFormatJsCode(rootState: IRootState) {
      return (
        rootState.browserPersistentSettings.numericFormatJsCode ??
        getDefaultNumericFormatCode(rootState.profile?.user?.created)
      );
    },
    suppressLegalDocuments(rootState: IRootState) {
      return rootState.legalDocumentSuppressors.length > 0;
    },
    showBuyCoinScreens(rootState: IRootState) {
      if (process.env.showBuyCoinScreens === 'true') {
        return true;
      }
      if (process.env.showBuyCoinScreens === 'admin') {
        if (rootState.profile) {
          return rootState.profile.user.role === 'admin';
        }
      }
      return false;
    },
  },
};

export default () => {
  const storeInstance = new Vuex.Store<IRootState>(store);

  let lastSavedBrowserPersistentSettings = {};
  storeInstance.subscribe((_, state) => {
    if (
      state.browserPersistentSettings !== lastSavedBrowserPersistentSettings
    ) {
      localStorage.setItem(
        'galaBrowserPersistentSettings',
        JSON.stringify(state.browserPersistentSettings),
      );
      lastSavedBrowserPersistentSettings = state.browserPersistentSettings;
    }
  });

  storeInstance.state.browserPersistentSettings = JSON.parse(
    localStorage.getItem('galaBrowserPersistentSettings') || '{}',
  );

  return storeInstance;
};
